import React from "react"
import { useTheme } from "@emotion/react"
import DatePicker from "react-datepicker"
import shortid from "shortid"
import css from "@styled-system/css"

import { Heading, Text } from "components"

import "react-datepicker/dist/react-datepicker.css"

const DatePickerComp = ({ placeholder, label, ...rest }) => {
  const theme = useTheme()
  const uid = shortid.generate()
  return (
    <>
      {label && (
        <Heading
          as="label"
          //htmlFor={uid}
          size={100}
          display="block"
          mb={["spacing.1", null, null, "layout.1"]}
        >
          {label}
          {rest.required && (
            <Heading
              size="100"
              display={"inline-block"}
              children={`Required`}
              opacity={0.3}
              ml="layout.1"
            />
          )}
        </Heading>
      )}
      <Text
        as="div"
        size={500}
        data-component-id="date-picker"
        position="relative"
        borderRadius={0}
        boxShadow={`inset 0 0 0 4px ${theme.colors.text}`}
        height={48}
        css={css({
          ".react-datepicker-wrapper": {
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingLeft: "12px",
            paddingRight: "12px",
            height: "100%",
            width: "100%",
          },
          ".react-datepicker__input-container": {
            height: "100%",
            width: "100%",
          },
          "> div > div > input": {
            fontSize: "18px",
            lineHeight: "22px",
            border: "none",
            height: "100%",
            width: "100%",
            "::placeholder": {
              color: "text",
              opacity: 1 / 3,
            },
            ":focus": {
              outline: 0,
            },
          },
        })}
      >
        <DatePicker id={uid} placeholderText={placeholder} {...rest} />
      </Text>
    </>
  )
}

DatePickerComp.defaultProps = {
  showTimeSelect: true,
  timeFormat: "HH:mm",
  timeIntervals: 15,
  timeCaption: "time",
  dateFormat: "MMMM d, yyyy h:mm aa",
}

export default DatePickerComp
