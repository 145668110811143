import React from "react"
import moment from "moment-timezone"
import { Link as GatsbyLink } from "gatsby"

import {
  AspectRatio,
  Box,
  ColorMode,
  Flex,
  Heading,
  Icon,
  Image,
  Paragraph,
} from "components"

function EventCard({
  image,
  title,
  url,
  category,
  address,
  startDate,
  endDate,
  vertical,
}) {
  return (
    <Flex
      data-component-id="eventCard"
      as={GatsbyLink}
      to={category === "Campaign" ? `/${url}/` : `/events/${url}/`}
      flexDirection={vertical ? "column" : "row"}
      minHeight="100%"
      border={["4px solid", "8px solid"]}
      borderColor="text"
    >
      {/* ~~~~~~ HORIZONTAL ~~~~~~ */}
      {/* ============ IMAGE ============ */}
      {!vertical && (
        <>
          {image && image.url && (
            <Flex position="relative" width={["40%", "50%"]} pb="50%">
              <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                bg="wash"
              >
                <Image
                  image={image}
                  size="xs"
                  style={{ height: "100%" }}
                  imgStyle={{ height: "100%", objectFit: "cover" }}
                />
                {/* ============ DATE ============ */}
                <Flex
                  position="absolute"
                  top={0}
                  left={0}
                  justifyContent="flex-start"
                  alignItems="center"
                  width="100%"
                  height={[32, 40]}
                  px="layout.1"
                  borderBottom={["4px solid", "8px solid"]}
                  borderColor="text"
                  bg="background"
                >
                  {category === "Campaign" && (
                    <Heading
                      size={300}
                      children={moment(startDate)
                        .tz("America/Edmonton")
                        .format("MMMM")}
                    />
                  )}
                  {category !== "Campaign" && (
                    <Heading size={300}>
                      {moment(startDate)
                        .tz("America/Edmonton")
                        .dayOfYear() ===
                      moment(endDate)
                        .tz("America/Edmonton")
                        .dayOfYear()
                        ? moment(startDate)
                            .tz("America/Edmonton")
                            .format("MMM DD")
                        : moment(startDate)
                            .tz("America/Edmonton")
                            .format("MMM DD") +
                          " – " +
                          moment(endDate)
                            .tz("America/Edmonton")
                            .format("MMM DD")}
                    </Heading>
                  )}
                </Flex>
              </Box>
            </Flex>
          )}
          {(!image || !image.url) && (
            <Box width="8px" ml="-8px">
              <Box width="100%" pb={["1950%", "3400%"]} />
            </Box>
          )}
        </>
      )}

      {/* ~~~~~~ VERTICAL ~~~~~~ */}
      {/* ============ IMAGE ============ */}
      {vertical && image && image.url && (
        <AspectRatio ratio="16:9" bg="wash">
          <Image
            image={image}
            size="xs"
            style={{ height: "100%" }}
            imgStyle={{ height: "100%", objectFit: "cover" }}
          />
          {/* ============ DATE ============ */}
          <Flex
            position="absolute"
            top={0}
            left={0}
            justifyContent="center"
            alignItems="center"
            width="100%"
            height={[32, 40]}
            px="layout.1"
            borderBottom="2px solid"
            borderColor="text"
            bg="background"
          >
            <Heading size={300} textAlign="center">
              {moment(startDate)
                .tz("America/Edmonton")
                .dayOfYear() ===
              moment(endDate)
                .tz("America/Edmonton")
                .dayOfYear()
                ? moment(startDate)
                    .tz("America/Edmonton")
                    .format("MMM DD")
                : moment(startDate)
                    .tz("America/Edmonton")
                    .format("MMM DD") +
                  " – " +
                  moment(endDate)
                    .tz("America/Edmonton")
                    .format("MMM DD")}
            </Heading>
          </Flex>
        </AspectRatio>
      )}

      {/* ============ CONTENT ============ */}
      <ColorMode mode="dark">
        <Flex
          flexDirection="column"
          flex={1}
          width={vertical ? "100%" : ["60%", "50%"]}
          pt={["spacing.4", "spacing.5"]}
          pr={["spacing.4", "spacing.6"]}
          pb={["spacing.4", "spacing.6"]}
          pl={
            !image || !image.url
              ? ["spacing.2", "layout.1"]
              : ["spacing.4", "spacing.6"]
          }
          bg="background"
        >
          {/* ============ DATE IF NO IMAGE IS PRESENT ============ */}
          {(!image || !image.url) && (
            <Flex
              justifyContent="left"
              alignItems="center"
              width="100%"
              width={["40%", "50%"]}
              height={[24, 32]}
              px="layout.1"
              bg="text"
              mt={["-12px", "-16px"]}
              ml={["-4px", "-8px"]}
              mb="16px"
            >
              <Heading size={300} textAlign="center" color="background">
                {moment(startDate)
                  .tz("America/Edmonton")
                  .dayOfYear() ===
                moment(endDate)
                  .tz("America/Edmonton")
                  .dayOfYear()
                  ? moment(startDate)
                      .tz("America/Edmonton")
                      .format("MMM DD")
                  : moment(startDate)
                      .tz("America/Edmonton")
                      .format("MMM DD") +
                    " – " +
                    moment(endDate)
                      .tz("America/Edmonton")
                      .format("MMM DD")}
              </Heading>
            </Flex>
          )}
          {/* Title */}
          <Heading size={600} mb="auto">
            <GatsbyLink
              to={category === "Campaign" ? `/${url}/` : `/events/${url}/`}
            >
              {title}
            </GatsbyLink>
          </Heading>

          {/* Venue */}
          {address && (
            <Paragraph size={300} mt={["spacing.3", "spacing.5"]}>
              {address}
            </Paragraph>
          )}

          {/* Category */}
          {category && category.heroTitle && (
            <Box mt="spacing.3">
              <Flex
                as={GatsbyLink}
                to={`/${category.url}/`}
                display="inline-flex"
                alignItems="center"
              >
                <Icon symbol={category.icon} size={20} color="text" />
                <Heading size={100} ml="spacing.2" lineHeight="20px">
                  {category.heroTitle}
                </Heading>
              </Flex>
            </Box>
          )}
        </Flex>
      </ColorMode>
    </Flex>
  )
}

export default EventCard
