import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import css from "@styled-system/css"

import { Box, Flex, Icon, Heading, Link, Subheader } from "components"

const Item = ({ label, symbol, to, onClick }) => (
  <Box data-component-id="navBar.list.item" as="li" onClick={onClick}>
    <Link
      to={to}
      activeClassName="current"
      partiallyActive={true}
      css={css({
        "&.current": {
          span: {
            color: "background",
            bg: "text",
          },
        },
      })}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={44}
        height={48}
      >
        <Box
          as="span"
          display="block"
          p="spacing.2"
          borderRadius={9999}
          color="text"
        >
          <Icon symbol={symbol} size={24} color="inherit" />
        </Box>
        <Heading
          as="div"
          children={label}
          size={300}
          fontSize={12}
          lineHeight="16px"
        />
      </Flex>
    </Link>
  </Box>
)

function NavBar({ location, sublinks, setShowMenu }) {
  const { navigation } = useStaticQuery(graphql`
    {
      navigation: datoCmsNavigation {
        primaryNavigation {
          icon
          label
          link
        }
        secondaryNavigation {
          label
          link
        }
      }
    }
  `)

  return (
    <>
      <Box
        data-component-id="navBar"
        display={["block", "none"]}
        width="100%"
        pb="env(safe-area-inset-bottom)"
        bg="background"
        boxShadow="inset 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 -3px 3px rgba(0, 0, 0, 0.05)"
      >
        <Flex
          data-component-id="navBar.list"
          as="ul"
          justifyContent="space-between"
          m={0}
          px={5}
          py={3}
          css={{ listStyle: "none" }}
        >
          {/* ======================== PRIMARY NAVIGATION ITEMS ======================== */}
          {navigation.primaryNavigation &&
            navigation.primaryNavigation.map(({ label, icon, link }) => (
              <Item label={label} symbol={icon} to={link} />
            ))}
          {/* ======================== MENU BUTTON ======================== */}
          <Box
            data-component-id="navBar.list.item"
            as="li"
            onClick={() => {
              setShowMenu(true)
            }}
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width={44}
              height={48}
            >
              <Box
                as="span"
                display="block"
                p="spacing.2"
                borderRadius={9999}
                color="text"
              >
                <Icon symbol={"menu"} size={24} color="inherit" />
              </Box>
              <Heading
                as="div"
                children={"Menu"}
                size={300}
                fontSize={12}
                lineHeight="16px"
              />
            </Flex>
          </Box>
        </Flex>
        {/* ======================== SUBNAVIGATION ======================== */}
        <Subheader links={sublinks} location={location} />
      </Box>
    </>
  )
}

export default NavBar
