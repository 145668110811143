import React, { useEffect } from "react"

import { Box, ColorMode, FlexGrid, Heading, Wrapper } from "../components"

export const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/embed/v2.js"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "22418804",
          formId: "b9141127-4fb7-4295-8601-99ac32f4bf03",
          target: "#hubspotForm",
        })
      }
    })
  }, [])

  return (
    <Box data-component-id="subscribe" position="relative" bg="background">
      <ColorMode mode="dark">
        <Wrapper my={0} py={["layout.6", "layout.8"]} bg="wash">
          <FlexGrid
            content={[
              {
                width: ["100%", "50%"],
                children: <Heading size={900}>Be the first to know</Heading>,
              },
              {
                width: ["100%", "50%"],
                children: (
                  <Box>
                    <div id="hubspotForm" />
                  </Box>
                ),
              },
            ]}
          />
        </Wrapper>
      </ColorMode>
    </Box>
  )
}
