import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import css from "@styled-system/css"

import {
  Box,
  ColorMode,
  Flex,
  Heading,
  Icon,
  Paragraph,
  Wrapper,
} from "components"
import { Logo } from "./logo"

const MenuItem = ({ label, symbol, to }) => (
  <Box as="li">
    <Link
      to={to}
      activeClassName="current"
      partiallyActive={true}
      css={css({
        "&.current": {
          span: {
            color: "background",
            bg: "text",
          },
        },
        "&:hover": {
          "> div > div": {
            opacity: 0.45,
          },
        },
      })}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={44}
        height={64}
      >
        <Box
          as="span"
          display="block"
          p="spacing.2"
          borderRadius={9999}
          color="text"
        >
          <Icon symbol={symbol} size={32} color="inherit" />
        </Box>
        <Heading as="div" children={label} size={100} mt="auto" />
      </Flex>
    </Link>
  </Box>
)

const NavItem = ({ href, label, to }) => {
  if (!href) {
    return (
      <Heading
        as="li"
        size={400}
        mb="layout.1"
        css={{
          ":last-of-type": { marginBottom: 0 },
        }}
      >
        <Link
          to={to}
          activeClassName="current"
          partiallyActive={true}
          css={css({
            display: "inline-flex",
            transition: "opacity 200ms ease-out",
            svg: {
              display: "none",
            },
            "&.current": {
              svg: {
                display: "block",
              },
            },
            ":hover": { opacity: 0.65 },
          })}
        >
          <Icon symbol="arrowRight" size={20} color="text" mr="layout.1" />
          {label}
        </Link>
      </Heading>
    )
  } else {
    return (
      <Heading
        as="li"
        size={400}
        mb="layout.1"
        css={{
          ":last-of-type": { marginBottom: 0 },
        }}
      >
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          css={css({
            display: "inline-flex",
            transition: "opacity 200ms ease-out",
            svg: {
              display: "none",
            },
            "&.current": {
              svg: {
                display: "block",
              },
            },
            ":hover": { opacity: 0.65 },
          })}
        >
          <Icon symbol="arrowRight" size={20} color="text" mr="layout.1" />
          {label}
        </a>
      </Heading>
    )
  }
}

const SocialIcon = ({ symbol, href }) => (
  <Box
    display="block"
    p={["layout.1", "layout.2"]}
    css={{ ":hover": { opacity: 0.65 } }}
  >
    <a title={symbol} href={href} target="_blank" rel="noopener noreferrer">
      <Icon symbol={symbol} size={[32, 40]} />
    </a>
  </Box>
)

function Footer() {
  const { navigation } = useStaticQuery(graphql`
    {
      navigation: datoCmsNavigation {
        primaryNavigation {
          icon
          label
          link
        }
      }
    }
  `)
  return (
    <ColorMode mode="dark">
      <Wrapper
        data-component-id="footer"
        as="footer"
        position="relative"
        my={0}
        pt={["layout.6", "layout.7"]}
        pb="layout.6"
      >
        {/* MENU ITEMS */}
        <Box display={["block", "none"]} mb="layout.6">
          <Flex
            as="ul"
            justifyContent="space-between"
            m={0}
            p={0}
            css={{ listStyle: "none" }}
          >
            {navigation.primaryNavigation &&
              navigation.primaryNavigation.map(({ label, icon, link }) => (
                <MenuItem label={label} symbol={icon} to={link} />
              ))}
          </Flex>
        </Box>

        <Flex>
          {/* NAVIGATION ITEMS */}
          <Box as="ul" m={0} p={0} css={{ listStyle: "none" }}>
            <NavItem label="Experience Downtown" to="/experience-downtown/" />
            <NavItem label="Getting around" to="/getting-around/" />
            <NavItem label="Permits" to="/permits/" />
            <NavItem
              label="About the CDA"
              href="https://www.cda.downtowncalgary.com"
            />
            <NavItem
              label="Employment Opportunities"
              href="/news/employment-opportunities/"
            />

            <NavItem label="Search" to="/search/" />
            <NavItem label="Login" to="/members/" />
          </Box>

          {/* MENU ITEMS */}
          <Box
            display={["none", "block"]}
            minWidth={[320, null, null, 420]}
            ml="auto"
          >
            <Flex
              as="ul"
              justifyContent="space-between"
              m={0}
              p={0}
              css={{ listStyle: "none" }}
            >
              {navigation.primaryNavigation &&
                navigation.primaryNavigation.map(({ label, icon, link }) => (
                  <MenuItem label={label} symbol={icon} to={link} />
                ))}
            </Flex>
          </Box>

          {/* SOCIAL MEDIA */}
          <Box display={["block", "none"]} ml="auto">
            <Box m="-8px">
              <SocialIcon
                symbol="facebook"
                href="https://www.facebook.com/downtowncalgary/"
              />
              <SocialIcon
                symbol="instagram"
                href="https://www.instagram.com/downtowncalgary/"
              />
              <SocialIcon symbol="x" href="https://x.com/downtowncalgary" />
              <SocialIcon
                symbol="tiktok"
                href="https://www.tiktok.com/@downtowncalgary"
              />
            </Box>
          </Box>
        </Flex>

        {/* MID */}
        <Box mt="layout.6">
          <Flex alignItems="center">
            {/* CDA INFO */}
            <Flex flexDirection="column" alignItems="start">
              <Logo style={{ fontSize: 72 }} />
              <Paragraph size={300} flex={1} mt="layout.2">
                Downtown Calgary is operated by the <br />
                <Box as="span" fontWeight={700}>
                  Calgary Downtown Association
                </Box>
                .
              </Paragraph>
            </Flex>

            {/* SOCIAL MEDIA */}
            <Box display={["none", "block"]} ml="auto">
              <Flex m="-16px">
                <SocialIcon
                  symbol="facebook"
                  href="https://www.facebook.com/downtowncalgary/"
                />
                <SocialIcon
                  symbol="instagram"
                  href="https://www.instagram.com/downtowncalgary/"
                />
                <SocialIcon symbol="x" href="https://x.com/downtowncalgary" />
                <SocialIcon
                  symbol="tiktok"
                  href="https://www.tiktok.com/@downtowncalgary"
                />
              </Flex>
            </Box>
          </Flex>
        </Box>

        {/* PRIVACY, TERMS and COPYRIGHT */}
        <Box mt="layout.5">
          <Heading
            as="p"
            size={100}
            mb="layout.1"
            textAlign="center"
            color="text"
          >
            <Link to="/privacy/" css={{ ":hover": { opacity: 0.65 } }}>
              Privacy
            </Link>
            {" | "}
            <Link
              to="/terms-and-conditions/"
              css={{ ":hover": { opacity: 0.65 } }}
            >
              Terms & Conditions
            </Link>
          </Heading>
          <Heading as="p" size={100} textAlign="center" color="alt">
            Copyright {new Date().getFullYear()} Calgary Downtown Association
          </Heading>
        </Box>
      </Wrapper>
    </ColorMode>
  )
}

export default Footer
