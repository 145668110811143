import React, { useEffect } from "react"
import css from "@styled-system/css"

import { Box, ColorMode, Flex, IconButton, Link, Text } from "components"

const Item = ({ id, label, to, returnLink }) => (
  <Box height={48} id={id}>
    <Link
      to={to}
      activeClassName="current"
      partiallyActive={false}
      css={css({
        display: "flex",
        alignItems: "center",
        height: "100%",
        color: "text",
        transition: "opacity 200ms ease-out",
        "&:hover": {
          opacity: 0.65,
        },
        "> * + *": {
          display: "none !important",
        },
        "&.current": {
          color: "background",
          bg: "text",
          "> * + *": {
            display: "inline-flex !important",
          },
        },
      })}
    >
      <Text
        as="div"
        size={300}
        pl={["layout.2", "layout.3"]}
        color="inherit"
        css={{ whiteSpace: "nowrap" }}
        mr={returnLink ? 0 : ["layout.2", "layout.3"]}
      >
        {label}
      </Text>
      {returnLink && (
        <ColorMode mode="dark">
          <IconButton
            as={Link}
            to={returnLink}
            icon="close"
            appearance="minimal"
          />
        </ColorMode>
      )}
    </Link>
  </Box>
)

function Subheader({ location, links, returnLink }) {
  //scroll links into view if out of screen
  useEffect(() => {
    setTimeout(() => {
      if (location && location.pathname) {
        let scrollLink = document.getElementById(
          "subheaderLink-" + location.pathname
        )
        if (scrollLink) {
          let scrollContainer = document.getElementById(
            "subheaderScrollContainer"
          )
          if (scrollContainer) {
            scrollContainer.scrollLeft = 1000
          }
        }
      }
    }, 300)
  }, [location.pathname])

  return (
    <Box
      data-component-id="subheader"
      position="fixed"
      top={["auto", 140]}
      bottom={[63, "auto"]}
      left={0}
      zIndex={1}
      width="100%"
      px={[0, "layout.6"]}
      bg="background"
      overflow="hidden"
      boxShadow={[
        "inset 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 -3px 3px rgba(0, 0, 0, 0.05)",
        "none",
      ]}
    >
      <Flex
        mb="-32px"
        pb="32px"
        // px="layout.2"
        overflowX="scroll"
        id="subheaderScrollContainer"
        css={{ WebkitOverflowScrolling: "touch" }}
      >
        {links &&
          links.map(({ label, to }, index) => (
            <Item
              label={label}
              to={to}
              returnLink={returnLink}
              id={"subheaderLink-" + to}
              key={"subheaderLink" + index}
            />
          ))}
        {/* <Box pr="layout.2" /> */}
      </Flex>
    </Box>
  )
}

export default Subheader
