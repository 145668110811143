import React from "react"

import { Box, Wrapper } from "components"

function Divider({ noWrapper, ...rest }) {
  if (noWrapper)
    return (
      <Box
        data-component-id="divider.line"
        as="hr"
        width="100%"
        height={["4px", "8px"]}
        m={0}
        border={0}
        bg="text"
      />
    )
  else
    return (
      <Wrapper data-component-id="divider" {...rest}>
        <Box
          data-component-id="divider.line"
          as="hr"
          width="100%"
          height={["4px", "8px"]}
          m={0}
          border={0}
          bg="text"
        />
      </Wrapper>
    )
}

export default Divider
