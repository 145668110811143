import React, { useState, useEffect } from "react"
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button"
// import "@reach/menu-button/styles.css"
import css from "@styled-system/css"

import { Box, ColorMode, IconButton, Text } from "components"

const Button = ({ ...rest }) => (
  <Text
    as={MenuItem}
    py="spacing.2"
    px="layout.2"
    onSelect={() => alert("Facebook")}
    css={css({
      '&[data-selected=""]': {
        bg: "brand",
      },
      ":focus": {
        outline: 0,
      },
    })}
    {...rest}
  />
)

function ShareMenu() {
  const [shareLink, setShareLink] = useState("")

  //set share link to window location on component mount
  useEffect(() => {
    setShareLink(window.location.href)
  }, [])

  //copy text from hidden input
  const copyText = () => {
    let copyText = document.getElementById("shareLink")
    copyText.select()
    document.execCommand("copy")
  }

  return (
    <>
      <Menu>
        <IconButton as={MenuButton} icon="share" />
        <ColorMode mode="dark">
          <Box
            as={MenuList}
            display="block"
            whiteSpace="nowrap"
            bg="background"
            mt="-4px"
            p="spacing.2"
          >
            <Button
              children="Facebook"
              onSelect={() =>
                window.open(
                  "https://www.facebook.com/sharer/sharer.php?u=" +
                    encodeURIComponent(shareLink)
                )
              }
            />
            <Button
              children="Twitter"
              onSelect={() =>
                window.open(
                  "https://twitter.com/intent/tweet?text=" + shareLink
                )
              }
            />
            <Button
              children="LinkedIn"
              onSelect={() =>
                window.open(
                  "https://www.linkedin.com/shareArticle?mini=true&url=" +
                    encodeURIComponent(shareLink)
                )
              }
            />
          </Box>
        </ColorMode>
      </Menu>
    </>
  )
}

export default ShareMenu
