import React from "react"
import { useTheme } from "@emotion/react"
import css from "@styled-system/css"
import shortid from "shortid"

import { Box, Heading, Icon, Text } from "components"

const getTextSizeForControlHeight = height => {
  if (height <= 32) return 500
  if (height <= 40) return 500
  if (height <= 48) return 500
  return 600
}

const getIconSizeForButton = height => {
  if (height <= 32) return 16
  if (height <= 40) return 20
  if (height <= 48) return 24
  return 24
}

const Input = ({ bg, height, iconAfter, iconBefore, label, ...rest }) => {
  const theme = useTheme()
  const is = getIconSizeForButton(height)
  const p = Math.round(height / 4)
  const ts = getTextSizeForControlHeight(height)
  const uid = shortid.generate()

  return (
    <>
      {label && (
        <Heading
          as="label"
          // htmlFor={uid}
          size={100}
          display="block"
          mb={["spacing.1", null, null, "layout.1"]}
        >
          {label}
          {rest.required && (
            <Heading
              size="100"
              display={"inline-block"}
              children={`Required`}
              opacity={0.3}
              ml="layout.1"
            />
          )}
        </Heading>
      )}
      <Box
        data-component-id="input"
        position="relative"
        borderRadius={0}
        bg={bg}
        overflow="hidden"
        boxShadow={`inset 0 0 0 4px ${theme.colors.text}`}
      >
        {iconBefore && (
          <Box
            data-component-id="input.iconBefore"
            position="absolute"
            top={0}
            left={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={height}
            height={height}
            css={{ userSelect: "none", pointerEvents: "none" }}
          >
            <Icon symbol={iconBefore} size={is} color="gray.4" />
          </Box>
        )}
        <Text
          data-component-id="input.field"
          as="input"
          id={uid}
          size={ts}
          display="block"
          width="100%"
          height={height}
          m={0}
          pr={iconAfter ? height + "px" : p + "px"}
          pl={iconBefore ? height + "px" : p + "px"}
          py="10px"
          border={0}
          color="text"
          bg="transparent"
          css={css({
            "::placeholder": {
              color: "text",
              opacity: 1 / 3,
            },
            ":focus": {
              outline: 0,
            },
          })}
          {...rest}
        />
        {iconAfter && (
          <Box
            data-component-id="input.iconAfter"
            position="absolute"
            top={0}
            right={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={height}
            height={height}
            css={{ userSelect: "none", pointerEvents: "none" }}
          >
            <Icon symbol={iconAfter} size={is} color="gray.4" />
          </Box>
        )}
      </Box>
    </>
  )
}

Input.defaultProps = {
  height: 48,
  type: "text",
}

export default Input
