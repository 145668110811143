import React from "react"
import { useTheme } from "@emotion/react"
import TextareaAutosize from "react-textarea-autosize"
import css from "@styled-system/css"
import shortid from "shortid"
import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"
import { compose, variant } from "styled-system"

import { Box } from "../../box"
import {
  COMMON,
  BORDER,
  TYPOGRAPHY,
  LAYOUT,
  POSITION,
  FLEX,
  GRID,
} from "../../systemProps"
import { Heading } from "../../typography"

const getTextSizeForControlHeight = height => {
  if (height <= 32) return 500
  if (height <= 40) return 500
  if (height <= 48) return 500
  return 600
}

const Comp = styled(TextareaAutosize, { shouldForwardProp })(
  variant({
    prop: "size",
    scale: "text",
  }),
  compose(COMMON, BORDER, TYPOGRAPHY, LAYOUT, POSITION, FLEX, GRID)
)

const Textarea = ({ bg, label, height, ...rest }) => {
  const theme = useTheme()
  const p = Math.round(height / 4)
  const ts = getTextSizeForControlHeight(height)
  const uid = shortid.generate()

  return (
    <>
      {label && (
        <Heading
          as="label"
          //htmlFor={uid}
          size={100}
          display="block"
          mb={["spacing.1", null, null, "layout.1"]}
        >
          {label}
          {rest.required && (
            <Heading
              size="100"
              display={"inline-block"}
              children={`Required`}
              opacity={0.3}
              ml="layout.1"
            />
          )}
        </Heading>
      )}
      <Box
        data-component-id="textarea"
        bg={bg}
        overflow="hidden"
        boxShadow={`inset 0 0 0 4px ${theme.colors.text}`}
      >
        <Comp
          data-component-id="textarea.field"
          id={uid}
          size={ts}
          css={css({
            display: "block",
            width: "100%",
            height: height,
            m: 0,
            p: p + "px",
            border: 0,
            color: "text",
            bg: "transparent",
            resize: "none",
            "::placeholder": {
              color: "black",
              opacity: 1 / 3,
            },
            ":focus": {
              outline: 0,
            },
          })}
          {...rest}
        />
      </Box>
    </>
  )
}

Textarea.defaultProps = {
  height: 48,
}

export default Textarea
