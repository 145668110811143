import React from "react"
import { Link as GatsbyLink } from "gatsby"

function Link({ to, ...rest }) {
  if (to) {
    if (
      to.indexOf("http://") !== -1 ||
      to.indexOf("https://") !== -1 ||
      to.indexOf("tel:") !== -1 ||
      to.indexOf("mailto:") !== -1
    ) {
      return <a href={to} rel="nopener noreferrer" target="_blank" {...rest} />
    } else {
      return <GatsbyLink to={to} {...rest} />
    }
  } else {
    return null
  }
}

export default Link
