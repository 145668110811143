import React from "react"

import {
  AspectRatio,
  Box,
  ColorMode,
  Flex,
  Heading,
  Icon,
  Image,
  Paragraph,
  RichText,
} from "components"

function BusinessCard({
  image,
  title,
  description,
  website,
  category,
  subcategory,
  address,
  vertical,
}) {
  return (
    <Flex
      data-component-id="businessCard"
      as={"a"}
      href={website}
      target="_blank"
      rel="noopener noreferrer"
      flexDirection={vertical ? "column" : "row"}
      minHeight="100%"
      border="8px solid"
      borderColor="text"
    >
      {/* ~~~~~~ HORIZONTAL ~~~~~~ */}
      {/* ============ IMAGE ============ */}
      {!vertical && image && image.url && (
        <Flex position="relative" width={["50%", "70%"]} pb={["50%", "33%"]}>
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            bg="wash"
          >
            <Image
              image={image}
              size="sm"
              style={{ height: "100%" }}
              imgStyle={{ height: "100%", objectFit: "cover" }}
            />
            {/* Icon */}
            {category && category.icon && (
              <Flex
                position="absolute"
                top={0}
                left={0}
                justifyContent="center"
                alignItems="center"
                width={40}
                height={40}
                mt="-2px"
                ml="-2px"
                border="2px solid"
                borderColor="text"
                bg="background"
              >
                <Icon symbol={category.icon} size={24} color="text" />
              </Flex>
            )}
          </Box>
        </Flex>
      )}
      {vertical && image && image.url && (
        <AspectRatio ratio="16:9" bg="wash">
          <Image
            image={image}
            size="xs"
            style={{ height: "100%" }}
            imgStyle={{ height: "100%", objectFit: "cover" }}
          />
          {/* Icon */}
          {category && category.icon && (
            <Flex
              position="absolute"
              top={0}
              left={0}
              justifyContent="center"
              alignItems="center"
              width={40}
              height={40}
              mt="-2px"
              ml="-2px"
              border="2px solid"
              borderColor="text"
              bg="background"
            >
              <Icon symbol={category.icon} size={24} color="text" />
            </Flex>
          )}
        </AspectRatio>
      )}
      {/* Content */}
      <ColorMode mode="light">
        <Flex
          flexDirection="column"
          flex={1}
          width={vertical ? "100%" : ["60%", "50%"]}
          borderLeft={!vertical && "1px solid"}
          borderColor="text"
          bg="background"
        >
          {/* Info */}
          <Box
            mt="auto"
            pt={vertical ? "spacing.6" : "layout.2"}
            px={["spacing.4", "spacing.6"]}
            borderTop={vertical && "2px solid"}
            borderColor="text"
          >
            <Heading size={100} mb="spacing.1">
              {subcategory}
            </Heading>
            <Heading size={600}>{title}</Heading>
            <Paragraph size={300}>{address}</Paragraph>
          </Box>

          {/* Divider */}
          <Box
            as="hr"
            width="50%"
            height="1px"
            mx={0}
            my={["spacing.3", "spacing.5"]}
            border={0}
            bg="text"
          />

          {/* Description */}
          <Box px={["spacing.4", "spacing.6"]} pb={["spacing.4", "spacing.6"]}>
            <RichText
              size={300}
              content={{ html: description }}
              css={{
                "a:hover": {
                  opacity: 0.45,
                },
              }}
            />
          </Box>
        </Flex>
      </ColorMode>
    </Flex>
  )
}

BusinessCard.defaultProps = {
  vertical: false,
}

export default BusinessCard
