import React from "react"
import shortid from "shortid"
import css from "@styled-system/css"
import { hideVisually } from "polished"

import { Box, Text } from "components"

const Radio = ({ bg, children, indeterminate, size, ...rest }) => {
  const uid = shortid.generate()

  return (
    <Text
      data-component-id="radio"
      role="radio"
      as="label"
      //htmlFor={uid}
      size={size}
      position="relative"
      display="block"
      minHeight={24}
      pl={`calc(${24 + "px"} + 12px)`}
      css={{ userSelect: "none", cursor: "pointer" }}
    >
      <Box
        data-component-id="radio.hiddenInput"
        as="input"
        id={uid}
        type="radio"
        ref={el => el && (el.indeterminate = indeterminate)}
        css={css({
          ...hideVisually(),
          "+ div": {
            transition: "background 200ms ease-in-out",
          },
          ":checked + div": {
            bg: "text",
          },
        })}
        {...rest}
      />
      <Box
        data-component-id="radio.control"
        position="absolute"
        top={0}
        left={0}
        display="block"
        width={24}
        height={24}
        border="4px solid"
        borderColor="text"
        borderRadius={9999}
      />
      <Box
        data-component-id="radio.text"
        pt={["spacing.2", null, null, "spacing.1"]}
      >
        {children}
      </Box>
    </Text>
  )
}

Radio.defaultProps = {
  bg: "white",
  size: 400,
}

export default Radio
