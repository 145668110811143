import React from "react"
import { useTheme } from "@emotion/react"
import shortid from "shortid"

import { Box } from "../../box"
import { Icon } from "../../icon"
import { Heading, Text } from "../../typography"

const getTextSizeForControlHeight = height => {
  if (height <= 24) return 300
  if (height <= 28) return 300
  if (height <= 32) return 400
  if (height <= 36) return 400
  if (height <= 40) return 400
  return 500
}

const Select = ({
  name,
  required,
  children,
  label,
  height,
  id,
  round,
  ...rest
}) => {
  const theme = useTheme()

  const ts = getTextSizeForControlHeight(height)
  const p = Math.round(height / 4)

  const uid = shortid.generate()

  return (
    <>
      {label && (
        <Heading
          as="label"
          //htmlFor={uid}
          size={100}
          display="block"
          mb={["spacing.1", null, null, "layout.1"]}
        >
          {label}
          {required && (
            <Heading
              size="100"
              display={"inline-block"}
              children={`Required`}
              opacity={0.3}
              ml="layout.1"
            />
          )}
        </Heading>
      )}
      <Box
        data-component-id="select"
        position="relative"
        display="inline-flex"
        {...rest}
      >
        <Text
          data-component-id="select.control"
          as="select"
          id={uid}
          size={ts}
          width="100%"
          height={height}
          m={0}
          pl={p + "px"}
          pr={24 + 8 + 4}
          py={0}
          border={0}
          borderRadius={0}
          lineHeight={height + "px"}
          bg="background"
          boxShadow={`inset 0 0 0 4px ${theme.colors.text}`}
          css={{
            userSelect: "none",
            appearance: "none",
            cursor: "pointer",
          }}
          name={name}
          required={required}
        >
          {children}
        </Text>
        <Icon
          symbol="chevronDown"
          size={24}
          position="absolute"
          top="50%"
          right="8px"
          color="text"
          css={{
            pointerEvents: "none",
            transform: "translateY(-50%)",
          }}
        />
      </Box>
    </>
  )
}

Select.defaultProps = {
  height: 48,
  size: 300,
}

export default Select
