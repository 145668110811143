import React from "react"
import styled from "@emotion/styled"
import css from "@styled-system/css"

import { Box } from "components"
import headings from "components/theme/src/headings"
import paragraph from "components/theme/src/paragraph"

const StyledBox = styled(Box)(props =>
  css({
    "*": { mb: 0, color: "text", ":first-child": { mt: 0 } },
    h1: {
      ...headings["700"],
      mt: "2em",
    },
    h2: {
      ...headings["600"],
      mt: "2em",
    },
    h3: {
      ...headings["500"],
      mt: "2em",
    },
    h4: {
      ...headings["400"],
      mt: "2em",
    },
    h5: {
      ...headings["300"],
      mt: "2em",
    },
    h6: {
      ...headings["100"],
      mt: "2em",
    },
    p: {
      ...paragraph[props.size],
    },
    a: {
      textDecoration: "underline",
      color: "brand",
    },
    strong: {
      fontWeight: "bold",
    },
    em: {
      fontStyle: "italic",
    },
    ".block-img": {
      img: { maxWidth: "100%" },
    },
    '[data-oembed-type="video"]': {
      position: "relative",
      pb: (270 / 480) * 100 + "%",
      iframe: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      },
    },
    ol: {
      width: "100%",
      maxWidth: 768 - 128,
      mx: "auto",
      p: 0,
      listStyle: "none",
      counterReset: "my-awesome-counter",
      li: {
        position: "relative",
        pl: 32,
        counterIncrement: "my-awesome-counter",
        "&::before": {
          content: "counter(my-awesome-counter)",
          position: "absolute",
          top: 0,
          left: 0,
          ...headings["600"],
          fontSize: "24px !important",
          lineHeight: "1 !important",
        },
        "+ li": {
          mt: "layout.2",
        },
      },
    },
    ul: {
      width: "100%",
      maxWidth: 768 - 128,
      mx: "auto",
      p: 0,
      listStyle: "none",
      li: {
        position: "relative",
        pl: 32,
        "&::before": {
          content: "'•'",
          position: "absolute",
          top: 0,
          left: 0,
          ...headings["600"],
          fontSize: "24px !important",
          lineHeight: "1 !important",
        },
        "+ li": {
          mt: "layout.2",
        },
      },
    },
    blockquote: {
      width: "100%",
      maxWidth: 768 - 128,
      mx: "auto",
      my: ["layout.4", "layout.6"],
      p: 0,
      "::before": {
        content: "''",
        display: "block",
        width: [40, null, 48],
        height: [40, null, 48],
        mx: "auto",
        mb: "layout.2",
        backgroundImage:
          "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAYAAAA5ZDbSAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAT+SURBVHgB7ZyBTeQwEEW9pyuAEqADSoAO6ABKgAoQFQAVABUAFUAH0AFQAXSQy5x0iJ3zHcTzxwlf/0mLtBKK7Dx7MrZnsxpGiqDlRxHUSDA5EkyOBJMjweRIMDkSTI4EkyPB5EgwORJMjgSTI8HkSDA5EkzObIIvLy/LarVK+RwdHZW5yeyfXfvLDDOxs7NjhQYpn4eHh2FuNjc30/r39PT05XbMItgamNV5u7Fzc3d3l9Y/mxhTmCVEn5yclCwODw/L3FxdXZUs9vf3J/1/9xlcm73X19dDC7VrTQlfGdTaZDO6BXvURPvXXfDFxQUspPprTQ1fGRwcHKy1aXt7e2hljEbh/nUX7JMPk9SK3TzUtVAg+4e4VlfBfsZFQuoSwzMyOtUStdfX12EqXQX7pZGFs1bOzs4WF579jIv0z4f61mt1E4yecchQiKA245D9a01Euwn2IzIy4xDZJRrUjDP8YNnY2Bha6SLYbr41EjXjfHa5t7c3zAlyaWQgB0sXwcjkw1haePYDDt2/yGDpItg3+PT0dGgFlV0iQQ64m5sb6GBJF4xOPpDhC0EtOkUGHLp/6YLt+YhsMCq7ROE3WyL9s4Hhc5VIeDZSBdeSj8hRHjK7RIDO5tG5ipF6muRPjcbR/vvTij+lGaNDmZPz8/O179aeUUpp5fb2du37uJQsYYZE0NkuMruMgjwV+9f1EIULaYLR4QadXUaxlQCyPRnh2UgL0T48R8PpKHjtOyR8BfDh+fj4uETwj58xWSsQhgTQSyPLLgtwpyiKn22W7EX6l3kyljKDa6Mxknz42WvXmnMG15K9SP/u7+/XvlsiGrneGgMY9L6s4dfStjU4FxmzzR+jIrde4YL9Tkw0WcjKLltBnooZ2YULcMHopVFWdtlCTUa0f9mFC1DBGTJ8+LIbMhcZ/cuuK4MKRu7LGkuru0JHpx79gwlGL40MP2MiJahR/EYLon+IstjPgAn2yQeiyiIzu4y2BXFM2aNwASI4Y2m0pPCckcn3KlyAbHRkbETUTlZgi/+J+G1Xa0vkVMyobZaMO2IFzgAgI9Qspe6qdgif0b+swoWw4Nq+bDTULKksNmNp1LNwIRyiM0INeq83gg/PiFOeroULQ4CsRGgp4Tlj6Wf0LFwICUbvyxpLKotFFwwavQsXmgWjS1b+sJSy2Iyln9G7f82Csw4BllIWi/wh90fQZbGf0Sw44zm5pLLYjP7NcTLWJBj5Q+6PLCU8Z4nIeKZ/xs/SALok5w++dOXx8bHs7u6WKYxywm2p7VxFeXt7+2vHr6V/Y5Sb9P+TZ3DW0qh2WtPyibYla2lUi3otn6lM3uiojW7E7PWjey6yolPmu7P+yzCRjH3Z2n5v62eJ5bnIN/tNZWV/ygTsZZhugJQo9nyy51ELNsNeXl7ev483s3nGPT8/l62trffvtuU6Si9RIv3zz+ip97spyUJjN3LuXyrUQB3fzdk/vS+aHAkmR4LJkWByJJgcCSZHgsmRYHIkmBwJJkeCyZFgciSYHAkmR4LJkWByJJgcCSZHgsmRYHIkmBwJJkeCyZFgciSYHAkmR4LJkWByJJgcCSZHgsmRYHIkmJzwL/z9Kx2YsFc6fPf+aQaTI8HkSDA5k1+jJL4XmsHkSDA5EkyOBJMjweRIMDkSTI4EkyPB5EgwORJMjgSTI8HkSDA5EkyOBJMjweRIMDkSTM4vTV5Ww+pzidcAAAAASUVORK5CYII=')",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      },
      p: {
        ...headings["600"],
        textAlign: "center",
      },
    },
  })
)

const RichText = ({ content, ...rest }) => (
  <StyledBox
    data-component-id="richText"
    dangerouslySetInnerHTML={{ __html: content.html }}
    {...rest}
  />
)

RichText.defaultProps = {
  size: "500",
}

export default RichText
