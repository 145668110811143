import React, { useState, useEffect, useCallback } from "react"
import { motion, useViewportScroll } from "framer-motion"

function FadeWrapper({
  children,
  duration,
  delayInitialCheck,
  delay,
  ...rest
}) {
  const fadeVariants = {
    initial: {
      y: 24,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        opacity: {
          duration: duration,
          delay: delay,
        },
        y: {
          duration: 1.3,
          delay: delay,
        },
      },
    },
  }
  const [show, setShow] = useState(false)
  const [wrapper, setWrapper] = useState(undefined)
  const measuredRef = useCallback(node => {
    setTimeout(() => {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      if (node !== null) {
        if (node.getBoundingClientRect().top < height) {
          setShow(true)
        } else {
          setWrapper(node)
        }
      }
    }, delayInitialCheck)
  }, [])
  const { scrollY } = useViewportScroll()

  useEffect(() => {
    if (wrapper) {
      scrollY.onChange(v => {
        const height =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight
        if (
          wrapper.getBoundingClientRect().top < height - height / 3 &&
          !show
        ) {
          setShow(true)
        }
      })
    }
  }, [wrapper])

  return (
    <div ref={measuredRef} {...rest}>
      <motion.div
        variants={fadeVariants}
        initial="initial"
        animate={show ? "enter" : "initial"}
      >
        {children}
      </motion.div>
    </div>
  )
}

FadeWrapper.defaultProps = {
  delay: 0,
  delayInitialCheck: 800,
  duration: 0.6,
}

export default FadeWrapper
