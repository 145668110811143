import React from "react"

import { Box, Flex, Icon } from "components"

const Pin = ({ onClick, icon }) => (
  <Box
    position="relative"
    css={{
      cursor: "pointer",
      transform: "translate(-50%, -100%)",
    }}
    onClick={e => {
      e.preventDefault()
      e.stopPropagation()
      onClick()
    }}
  >
    <Flex
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      width={48}
      height={48}
      border="4px solid"
      borderColor="text"
      borderRadius={9999}
      bg="brand"
    >
      <Box p="spacing.2">
        <Icon symbol={icon} size={24} color="#fff" />
      </Box>
    </Flex>
  </Box>
)

export default Pin
