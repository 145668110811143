import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import PropTypes from "prop-types"
import { ThemeProvider } from "@emotion/react"
import Helmet from "react-helmet"

import {
  Box,
  Footer,
  GlobalStyles,
  Header,
  Loader,
  NavBar,
  Theme,
} from "../components"

import Menu from "./_menu"
import { HubSpotForm } from "../components/hubspot-form"

require("intersection-observer")

const duration = 0.3

function Layout({ location, children }) {
  const { campaigns, experiences } = useStaticQuery(graphql`
    {
      experiences: allDatoCmsExperience {
        nodes {
          url
          experience
        }
      }
      campaigns: allDatoCmsCampaign {
        nodes {
          url
          navTitle
          showOnAbout
        }
      }
    }
  `)

  const [sublinks, setSublinks] = useState([])
  const [loaderActive, setLoaderActive] = useState(true)
  const [pageActive, setPageActive] = useState(false)
  const [loaderComplete, setLoaderComplete] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const [isMobile, setIsMobile] = useState(() => {
    if (typeof window !== "undefined") {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      if (width >= 832) {
        return false
      } else {
        return true
      }
    }
  })

  const checkWidth = e => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    if (width >= 832) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }

  useEffect(() => {
    // Set static viewport height unit on initial load
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    checkWidth()
    window.addEventListener("resize", checkWidth)
    return function cleanup() {
      window.removeEventListener("resize", checkWidth)
    }
  }, [])

  //sequence page loading animations and feed props appropriately
  useEffect(() => {
    if (!loaderActive) {
      setPageActive(true)
      setTimeout(() => {
        document.documentElement.style.position = ""
        document.documentElement.style.height = ""
        document.documentElement.style.overflow = ""
      }, 600)
      setTimeout(() => {
        setLoaderComplete(true)
      }, 2000)
    } else {
      document.documentElement.style.position = "relative"
      document.documentElement.style.height = "100%"
      document.documentElement.style.overflow = "hidden"
    }
  }, [loaderActive])

  useEffect(() => {
    //setup an effect to detect route changes and automatically close the menu
    setShowMenu(false)
    let campaignFound = false
    campaigns.nodes.forEach(campaign => {
      if (location.pathname === "/" + campaign.url + "/") {
        campaignFound = true
      }
    })
    if (
      location.pathname === "/experience-downtown/" ||
      location.pathname.indexOf("/experiences/") !== -1 ||
      location.pathname.indexOf("/about/") !== -1 ||
      campaignFound
    ) {
      if (location.pathname.indexOf("/about/") !== -1 || campaignFound) {
        let SUBNAV_ARRAY = [
          { label: "The CDA", to: "/about/" },
          { label: "News & Initiatives", to: "/about/news/" },
          { label: "Business Listings", to: "/about/businesses/" },
          { label: "Contact the CDA", to: "/about/contact/" },
        ]

        // Push campaign items to array before setting.
        campaigns.nodes.forEach(campaign => {
          if (campaign.showOnAbout) {
            SUBNAV_ARRAY.push({
              label: campaign.navTitle,
              to: "/" + campaign.url + "/",
            })
          }
        })

        setSublinks(SUBNAV_ARRAY)
      } else {
        setSublinks(
          experiences.nodes
            ? experiences.nodes.map(experience => ({
                label: experience.experience,
                to: "/experiences/" + experience.url + "/",
              }))
            : []
        )
      }
    } else {
      setSublinks([])
    }
  }, [location.pathname])

  return (
    <ThemeProvider theme={Theme}>
      <>
        <Helmet>
          {/* Inject fonts */}
          {/* <link rel="stylesheet" href="https://use.typekit.net/qgd1uhq.css" /> */}

          {/* Enable iPhone X and greater env() padding for properly avoiding bottom bar */}
          <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        </Helmet>

        <GlobalStyles />

        <motion.header
          variants={{
            initial: {
              y: "-150%",
            },
            enter: {
              y: 0,
              transition: {
                delay: 0.6,
                duration: 0.6,
                when: "beforeChildren",
                ease: [0.25, 0.46, 0.45, 0.94],
              },
            },
            exit: {
              transition: { duration: 0.8 },
            },
          }}
          style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 1000 }}
          initial="initial"
          animate={pageActive ? "enter" : "initial"}
          exit="exit"
        >
          <Header
            location={location}
            sublinks={sublinks}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
          />
        </motion.header>
        <motion.main
          variants={{
            initial: {
              opacity: 0,
            },
            enter: {
              opacity: 1,
              transition: {
                duration: 0.8,
                when: "beforeChildren",
                ease: [0.25, 0.46, 0.45, 0.94],
              },
            },
            exit: {
              transition: { duration: 0.8 },
            },
          }}
          initial="initial"
          animate={pageActive ? "enter" : "initial"}
          exit="exit"
        >
          <AnimatePresence>
            <motion.div
              key={location.pathname}
              variants={{
                initial: {
                  opacity: 0,
                },
                enter: {
                  opacity: 1,
                  transition: {
                    duration: duration,
                    delay: duration,
                    when: "beforeChildren",
                  },
                },
                exit: {
                  opacity: 0,
                  transition: { duration: duration },
                },
              }}
              initial="initial"
              animate={"enter"}
              exit="exit"
            >
              {React.cloneElement(children, {
                isMobile: isMobile,
                loaderComplete: loaderComplete,
              })}
            </motion.div>
          </AnimatePresence>
        </motion.main>
        <HubSpotForm />
        <Footer />
        <motion.header
          variants={{
            initial: {
              y: "200%",
            },
            enter: {
              y: 0,
              transition: {
                delay: 0.6,
                duration: 0.6,
                when: "beforeChildren",
                ease: [0.25, 0.46, 0.45, 0.94],
              },
            },
            exit: {
              transition: { duration: 0.8 },
            },
          }}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
          initial="initial"
          animate={pageActive ? "enter" : "initial"}
          exit="exit"
        >
          <NavBar
            location={location}
            sublinks={sublinks}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
          />
        </motion.header>
        <Box display={["block", "none"]} height={64} bg="text" opacity={0} />

        <Loader
          active={loaderActive}
          setActive={setLoaderActive}
          isMobile={isMobile}
        />
        <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
