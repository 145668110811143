import React from "react"
import css from "@styled-system/css"

import { Flex } from "components"

const FlexGrid = ({ content, gutterX, gutterY, ...rest }) => {
  let invertedGutterX = gutterX.map(function(num) {
    return -num
  })
  let invertedGutterY = gutterY.map(function(num) {
    return -num
  })

  return (
    <Flex
      data-component-id="flexGrid"
      css={css({
        flexWrap: "wrap",
        mx: invertedGutterX,
        my: invertedGutterY,
        "> *": {
          px: gutterX,
          py: gutterY,
        },
      })}
      {...rest}
    >
      {content.map((item, index) => (
        <Flex
          data-component-id="flexGrid.item"
          flexDirection="column"
          width={item.width && item.width}
          key={index}
          {...item}
        >
          {item.children}
        </Flex>
      ))}
    </Flex>
  )
}

FlexGrid.defaultProps = {
  gutterX: [16],
  gutterY: [16],
}

export default FlexGrid
