import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { linearGradient } from "polished"
import { animateScroll } from "react-scroll"

import {
  Box,
  Button,
  ColorMode,
  Flex,
  Heading,
  IconButton,
  Image,
  Wrapper,
} from "components"

function PageWrapper({
  loaderComplete,
  image,
  subtitle,
  title,
  page,
  buttonProps,
  backButton,
  minHeight,
  heavyTint,
  ...rest
}) {
  //a boilerplate function to scroll midway down page, with overrides based on current scroll position
  const scrollHalf = () => {
    if (window.scrollY <= 48) {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      animateScroll.scrollMore(height / 3)
    }
  }

  useEffect(() => {
    //scroll page halfway to prompt exploration, with modified timeout dependent on if this is the first CDA page loaded or not
    setTimeout(
      () => {
        scrollHalf()
      },
      !loaderComplete ? 3000 : 800
    )
  }, [])

  return (
    <>
      <ColorMode mode="dark">
        <Box
          data-component-id="pageWrapper.hero"
          my={0}
          pt={[0, 140]}
          position="relative"
          {...rest}
        >
          <Box
            position="fixed"
            top={0}
            left={0}
            zIndex={-1}
            width="100vw"
            height="calc(var(--vh, 1vh) * 100)"
            bg="background"
          >
            <Image
              image={image}
              size="lg"
              style={{
                height: "100%",
                width: "100%",
              }}
              imgStyle={{ height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Flex
            position="relative"
            flexDirection="column"
            justifyContent="flex-end"
            minHeight={
              minHeight
                ? minHeight
                : [
                    "calc((var(--vh, 1vh) * 100) - 64px)",
                    "calc((var(--vh, 1vh) * 100) - 140px)",
                  ]
            }
            overflow="hidden"
          >
            {/* Overall color overlay */}
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              css={{
                ...linearGradient({
                  colorStops: [
                    "rgba(0, 0, 0, 0.125) 0%",
                    "rgba(0, 0, 0, 0.125) 100%",
                  ],
                  toDirection: "to top",
                  fallback: "transparent",
                }),
              }}
            />

            {/* Top content gradient to draw out white elements. Hide on desktop as it is not needed. */}
            <Box
              position="fixed"
              top={0}
              left={0}
              display={["block", "none"]}
              width="100%"
              height={160}
              css={{
                ...linearGradient({
                  colorStops: [
                    "rgba(0, 0, 0, 0.5) 0%",
                    "rgba(0, 0, 0, 0) 100%",
                  ],
                  toDirection: "to bottom",
                  fallback: "transparent",
                }),
              }}
            />

            {/* Bottom content gradient to help draw out white elements against lighter background images */}
            <Box
              position="absolute"
              bottom={0}
              left={0}
              width="100%"
              height={160}
              css={{
                ...linearGradient({
                  colorStops: [
                    "rgba(0, 0, 0, 0.5) 0%",
                    "rgba(0, 0, 0, 0) 100%",
                  ],
                  toDirection: "to top",
                  fallback: "transparent",
                }),
              }}
            />

            <Wrapper my={["layout.3", "layout.6", "layout.7"]} bg="transparent">
              <Flex justifyContent="space-between" alignItems="flex-end">
                <Box position="relative">
                  {subtitle && (
                    <Heading size={700} mb="spacing.2">
                      {subtitle}
                    </Heading>
                  )}
                  <Heading size={900} ml="-0.05em">
                    {title}
                  </Heading>
                </Box>
                {buttonProps && (
                  <Box
                    position={["absolute", "relative"]}
                    top={[16, 0]}
                    right={[24, 0]}
                  >
                    <Button
                      display={["none", "inline-flex"]}
                      height={80}
                      mb="layout.1"
                      {...buttonProps}
                    />
                    <Button
                      display={["inline-flex", "none"]}
                      height={40}
                      css={{ padding: "0 1.25em", fontSize: "10px" }}
                      {...buttonProps}
                    />
                  </Box>
                )}
                {backButton && (
                  <Box position="absolute" top={[16, 48]} right={0}>
                    <Wrapper my={0} bg="transparent">
                      <IconButton
                        icon="chevronLeft"
                        onClick={() => {
                          if (window.history.length > 1) {
                            window.history.back()
                          } else {
                            navigate(backButton)
                          }
                        }}
                      />
                    </Wrapper>
                  </Box>
                )}
              </Flex>
            </Wrapper>
          </Flex>
        </Box>
      </ColorMode>
      <Box
        data-component-id="pageWrapper.page"
        position="relative"
        py="1px"
        bg="background"
      >
        {page}
      </Box>
    </>
  )
}

export default PageWrapper
