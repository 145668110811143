import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { useTheme } from "@emotion/react"

import { Button, FlexGrid, Heading, Wrapper } from "components"

const Tags = ({ tags, title }) => {
  const theme = useTheme()

  return (
    <>
      {tags && (
        <Wrapper maxWidth={768}>
          {/* ======================== TITLE ======================== */}
          {title && (
            <Heading
              size={700}
              maxWidth={640}
              mx="auto"
              mb="layout.2"
              textAlign="center"
            >
              {title}
            </Heading>
          )}

          {/* ======================== TAGS ======================== */}
          <FlexGrid
            justifyContent="center"
            content={tags.map(({ experience, url }) => {
              return {
                children: (
                  <Button
                    as={GatsbyLink}
                    to={`/experiences/${url}/`}
                    height={[48, 56]}
                    px="0.75em"
                    fontFamily="standard"
                    fontWeight="normal"
                    css={{
                      textTransform: "capitalize",
                      boxShadow: `inset 0 0 0 4px ${theme.colors.text} !important`,
                      transition: "box-shadow 150ms ease-out",
                      ":hover": {
                        color: "initial",
                        backgroundColor: "initial",
                        boxShadow: `inset 0 0 0 8px ${theme.colors.text} !important`,
                      },
                    }}
                  >
                    {experience}
                  </Button>
                ),
              }
            })}
            gutterX={[2, 3]}
            gutterY={[2, 3]}
            justifyContent={["flex-start", "center"]}
          />
        </Wrapper>
      )}
    </>
  )
}

export default Tags
