import React from "react"
import css from "@styled-system/css"
import chunk from "lodash.chunk"
import sum from "lodash.sum"

import { Box, Image, Wrapper } from "components"

const Gallery = ({
  id,
  images,
  itemsPerRow: itemsPerRowByBreakpoints,
  ...rest
}) => {
  const aspectRatios = images.map(image => image.width / image.height)

  // For each breakpoint, calculate the aspect ratio sum of each row's images
  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      // Split images into groups of the given size
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        // Sum aspect ratios of images in the given row
        sum(rowAspectRatios)
      )
  )

  return (
    <Wrapper data-component-id="gallery" maxWidth={768} {...rest}>
      <Box m={-2}>
        {images &&
          images.map((image, i) => (
            <Box
              width={rowAspectRatioSumsByBreakpoints.map(
                // Return a value for each breakpoint
                (rowAspectRatioSums, j) => {
                  // Find out which row the image is in and get its aspect ratio sum
                  const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j])
                  const rowAspectRatioSum = rowAspectRatioSums[rowIndex]

                  return `${(image.width / image.height / rowAspectRatioSum) *
                    100}%`
                }
              )}
              css={css({
                display: "inline-block",
                verticalAlign: "top",
              })}
            >
              <Box p={2}>
                <Image image={image} size="md" key={id + i} />
              </Box>
            </Box>
          ))}
      </Box>
    </Wrapper>
  )
}

Gallery.defaultProps = {
  itemsPerRow: [1, 2],
}

export default Gallery
