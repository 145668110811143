import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import css from "@styled-system/css"

import {
  Box,
  ColorMode,
  Flex,
  Icon,
  Heading,
  Link,
  Menu,
  Text,
  Wrapper,
} from "components"

const MenuItem = ({ label, symbol, to }) => (
  <Flex data-component-id="navBar.menu.item" as="li">
    <Link
      to={to}
      activeClassName="current"
      partiallyActive={true}
      css={css({
        "&.current": {
          span: {
            color: "background",
            bg: "text",
          },
        },
      })}
    >
      <Flex alignItems="center">
        <Flex
          as="span"
          justifyContent="center"
          alignItems="center"
          width={[32, 40]}
          height={[32, 40]}
          borderRadius={9999}
          color="text"
        >
          <Icon symbol={symbol} size={20} color="inherit" />
        </Flex>
        <Heading
          as="div"
          children={label}
          size={400}
          ml="spacing.2"
          fontSize="20px !important"
          lineHeight="32px"
        />
      </Flex>
    </Link>
  </Flex>
)

const SecondaryMenuItem = ({ label, to }) => (
  <Box data-component-id="navBar.menu.secondaryItem" as="li" my="spacing.2">
    <Heading as="div" size={400}>
      <Link
        to={to}
        activeClassName="current"
        partiallyActive={true}
        css={css({
          display: "flex",
          svg: {
            display: "none",
          },
          "&.current": {
            svg: {
              display: "block",
            },
          },
        })}
      >
        <Icon symbol="arrowRight" size={20} color="text" mr="layout.1" />
        {label}
      </Link>
    </Heading>
  </Box>
)

const Divider = ({ ...rest }) => (
  <Box
    data-component-id="navBar.menu.divider"
    height="1px"
    bg="text"
    my="layout.2"
    {...rest}
  />
)

const SocialIcon = ({ symbol, href }) => (
  <Box display="block" p={["layout.1", "layout.2"]}>
    <a title={symbol} href={href} target="_blank" rel="noopener noreferrer">
      <Icon symbol={symbol} size={[32, 40]} />
    </a>
  </Box>
)

const MembersSection = () => (
  <Box>
    <Text children="CDA Member?" size={300} />
    <Text size={300} fontWeight="bold">
      <Link to="/members/">
        Sign In
        <Icon
          symbol="arrowRight"
          size={12}
          strokeWidth={2}
          ml="spacing.1"
          verticalAlign="middle"
        />
      </Link>
    </Text>
  </Box>
)

function MenuSection({ showMenu, setShowMenu }) {
  const { navigation } = useStaticQuery(graphql`
    {
      navigation: datoCmsNavigation {
        primaryNavigation {
          icon
          label
          link
        }
        secondaryNavigation {
          label
          link
        }
      }
    }
  `)
  return (
    <>
      {/* ======================== MENU ======================== */}
      <Menu
        showDialog={showMenu}
        setShowDialog={setShowMenu}
        children={
          <ColorMode mode="dark">
            <Box minHeight="calc(var(--vh, 1vh) * 100)" bg="background">
              <Wrapper my={0}>
                <Box pt={24} pb={64 + 24}>
                  {/* ======================== PRIMARY MENU LINKS ======================== */}
                  {navigation.primaryNavigation && (
                    <Box as="ul" m={0} p={0} css={{ listStyle: "none" }}>
                      {navigation.primaryNavigation.map(
                        ({ label, icon, link }) => (
                          <MenuItem label={label} symbol={icon} to={link} />
                        )
                      )}
                    </Box>
                  )}
                  <Divider height="4px" />
                  {/* ======================== SECONDARY MENU LINKS ======================== */}
                  {navigation.secondaryNavigation && (
                    <Box as="ul" m={0} p={0} css={{ listStyle: "none" }}>
                      {navigation.secondaryNavigation.map(
                        ({ label, icon, link }) => (
                          <SecondaryMenuItem
                            label={label}
                            symbol={icon}
                            to={link}
                          />
                        )
                      )}
                      <SecondaryMenuItem
                        label={`Search`}
                        symbol={`search`}
                        to={`/search/`}
                      />
                    </Box>
                  )}
                  <Divider />
                  {/* ======================== SOCIAL ICONS ======================== */}
                  <Flex m="-8px">
                    <SocialIcon
                      symbol="facebook"
                      href="https://www.facebook.com/downtowncalgary/"
                    />
                    <SocialIcon
                      symbol="instagram"
                      href="https://www.instagram.com/downtowncalgary/"
                    />
                    <SocialIcon
                      symbol="twitter"
                      href="https://twitter.com/downtowncalgary"
                    />
                  </Flex>
                  <Divider />
                  {/* ======================== MEMBER SECTION ======================== */}
                  <MembersSection />
                </Box>
              </Wrapper>
            </Box>
          </ColorMode>
        }
      />
    </>
  )
}

export default MenuSection
