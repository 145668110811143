import React from "react"
import { useTheme } from "@emotion/react"

import { Icon } from "components"

import Button from "./Button"
import iconButtonStyles from "./iconButtonStyles"

const getIconSizeForButton = height => {
  if (height <= 28) return 16
  if (height <= 32) return 20
  if (height <= 40) return 24
  if (height <= 48) return 28
  return 32
}

const IconButton = ({ appearance, intent, height, icon, label, ...rest }) => {
  const is = getIconSizeForButton(height)

  return (
    <Button
      data-component-id="buttons.iconButton"
      width={height}
      height={height}
      px={0}
      css={{
        ...iconButtonStyles(appearance, intent, useTheme()),
        "> *": {
          display: "flex !important",
        },
      }}
      {...rest}
    >
      <Icon
        data-component-id="buttons.iconButton.icon"
        symbol={icon}
        label={label}
        size={is}
        strokeWidth={1}
        color="inherit"
      />
    </Button>
  )
}

IconButton.defaultProps = {
  appearance: "default",
  borderRadius: 0,
  height: 40,
  intent: "default",
}

export default IconButton
