import { rgba } from "polished"

export default {
  text: "#000",
  alt: rgba("#000", 0.5),
  background: "#fff",
  wash: rgba("#000", 0.1),
  brand: "#D21E27",
  modes: {
    dark: {
      text: "#fff",
      alt: rgba("#fff", 0.5),
      background: "#000",
      wash: rgba("#000", 0.8125),
      brand: "#D21E27",
    },
  },
}
