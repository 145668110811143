export default {
  "900": {
    fontFamily: "monotype-grotesque-display-c, sans-serif",
    fontSize: "58px",
    fontWeight: 700,
    lineHeight: "52px",
    textTransform: "uppercase",
    letterSpacing: "-0.0875em",
    "@media screen and (min-width: 52em)": {
      fontSize: "120px",
      lineHeight: "104px",
    },
  },
  "800": {
    fontFamily: "monotype-grotesque-display-c, sans-serif",
    fontSize: "40px",
    fontWeight: 700,
    lineHeight: "36px",
    textTransform: "uppercase",
    letterSpacing: "-0.075em",
    "@media screen and (min-width: 52em)": {
      fontSize: "68px",
      lineHeight: "64px",
    },
  },
  "700": {
    fontFamily: "monotype-grotesque-condensed, sans-serif",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "24px",
    textTransform: "uppercase",
    letterSpacing: "0.1em",
    "@media screen and (min-width: 52em)": {
      fontSize: "48px",
      lineHeight: "48px",
    },
  },
  "600": {
    fontFamily: "monotype-grotesque-display-c, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "-0.05em",
    "@media screen and (min-width: 52em)": {
      fontSize: "36px",
      lineHeight: "40px",
    },
  },
  // Default
  "500": {
    fontFamily: "monotype-grotesque-display-e, sans-serif",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "-0.05em",
    "@media screen and (min-width: 52em)": {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  "400": {
    fontFamily: "monotype-grotesque-display-e, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    textTransform: "uppercase",
    letterSpacing: "-0.05em",
    "@media screen and (min-width: 52em)": {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  "300": {
    fontFamily: "monotype-grotesque-condensed, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    textTransform: "uppercase",
    letterSpacing: "-0.025em",
    "@media screen and (min-width: 52em)": {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  "200": {
    fontFamily: "monotype-grotesque-extended, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "16px",
    textTransform: "uppercase",
    letterSpacing: "-0.025em",
    "@media screen and (min-width: 52em)": {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  "100": {
    fontFamily: "monotype-grotesque-extended, sans-serif",
    fontSize: "8px",
    fontWeight: 700,
    lineHeight: "16px",
    textTransform: "uppercase",
    "@media screen and (min-width: 52em)": {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
}
