import React from "react"

function symbols(symbol) {
  switch (symbol) {
    case "calendar":
      return (
        <>
          <title>Calendar</title>
          <path d="M22.468 25.543h-14.5c-1.534 0-2.789-1.243-2.789-2.763V8.418c0-1.52 1.255-2.763 2.79-2.763h14.5c1.534 0 2.789 1.243 2.789 2.763V22.78c0 1.52-1.255 2.763-2.79 2.763zM7.69 10.627h15.058M20.238 8.141V3.17M10.199 8.141V3.17" />
        </>
      )
    case "cuisine":
      return (
        <>
          <title>Cuisine</title>
          <path d="M25.569 4.034c0 5.911-4.786 10.7-10.692 10.7-5.905 0-10.691-4.789-10.691-10.7M9.532 25.435h10.691M14.877 25.435v-10.7M4.186 6.711h21.383" />
        </>
      )
    case "music":
      return (
        <>
          <title>Music</title>
          <path d="M8.068 25.806c2.136 0 3.868-1.154 3.868-2.577s-1.732-2.577-3.868-2.577-3.867 1.154-3.867 2.577 1.731 2.577 3.867 2.577zM21.404 23.229c2.136 0 3.868-1.154 3.868-2.577s-1.732-2.576-3.868-2.576-3.867 1.153-3.867 2.576 1.731 2.577 3.867 2.577z" />
          <path d="M11.936 23.229V6.569l13.336-3.332v17.326M11.936 11.382L25.272 8.05" />
        </>
      )
    case "ticket":
      return (
        <>
          <title>Ticket</title>
          <path d="M18.515 8.933V21.36" />
          <path d="M24.774 15.146c0-1.715 1.402-3.106 3.13-3.106V8.933H2.866v3.107c1.728 0 3.13 1.39 3.13 3.106s-1.402 3.107-3.13 3.107v3.107h25.038v-3.107c-1.728 0-3.13-1.391-3.13-3.107z" />
        </>
      )
    case "book":
      return (
        <>
          <title>Book</title>
          <path d="M8.534 24.677c-1.843 0-3.337-1.468-3.337-3.279 0-1.81 1.494-3.278 3.337-3.278h16.684c-1.843 0-3.337 1.468-3.337 3.278s1.494 3.279 3.337 3.279H8.534z" />
          <path d="M5.197 21.398V7.557c0-1.81 1.494-3.278 3.337-3.278l16.162.029v13.841" />
        </>
      )
    case "share":
      return (
        <>
          <title>Share</title>
          <path d="M11.089 16.692l7.74 3.909M18.87 9.48l-7.7 3.908M22.13 11.454c2.025 0 3.666-1.623 3.666-3.626S24.155 4.2 22.13 4.2s-3.667 1.624-3.667 3.627c0 2.003 1.642 3.626 3.667 3.626zM7.87 18.626c2.025 0 3.667-1.623 3.667-3.626s-1.642-3.626-3.667-3.626S4.204 12.997 4.204 15s1.641 3.626 3.666 3.626zM22.13 25.799c2.025 0 3.666-1.624 3.666-3.627 0-2.003-1.641-3.626-3.666-3.626s-3.667 1.623-3.667 3.626S20.105 25.8 22.13 25.8z" />
        </>
      )
    case "addCalendar":
      return (
        <>
          <title>Add to Calendar</title>
          <path d="M22.29 25.874H7.79c-1.263 0-2.29-1.024-2.29-2.263V9.249c0-1.24 1.027-2.263 2.29-2.263h14.5c1.262 0 2.289 1.024 2.289 2.263v14.362c0 1.24-1.027 2.263-2.29 2.263zM7.51 11.458h15.059M20.059 8.972V4M10.02 8.972V4" />
          <path d="M15 15v8m-4-4h8" />
        </>
      )
    case "expand":
      return (
        <>
          <title>Expand</title>
          <path d="M15.402 8v14.23m-7.027-7.115H22.43" />
        </>
      )
    case "collapse":
      return (
        <>
          <title>Collapse</title>
          <path d="M8 15h14.5" />
        </>
      )
    case "listView":
      return (
        <>
          <title>List View</title>
          <path d="M22 24H8c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2zM12 6v18M6 12h18M6 18h18" />
        </>
      )
    case "calendarView":
      return (
        <>
          <title>Calendar View</title>
          <path d="M21.177 24H8.527A2.536 2.536 0 016 21.472V8.822a2.536 2.536 0 012.528-2.527h12.646a2.535 2.535 0 012.528 2.528v12.646A2.531 2.531 0 0121.177 24zM7.967 10.557h13.77M19.443 8.59V4M10.262 8.59V4" />
          <path d="M10.882 13.167H8.075v2.807h2.807v-2.807zM16.21 13.167h-2.807v2.807h2.807v-2.807zM21.535 13.167h-2.807v2.807h2.806v-2.807zM10.882 18.17H8.075v2.807h2.807v-2.806zM16.21 18.17h-2.807v2.807h2.807v-2.806zM21.535 18.17h-2.807v2.807h2.806v-2.806z" />
        </>
      )
    case "mapView":
      return (
        <>
          <title>Map View</title>
          <path d="M12.055 8.37l5.89-2.205 4.706 1.744c.69.263 1.184.921 1.184 1.678V20.94c0 1.316-1.382 2.204-2.566 1.612l-3.29-1.58-5.891 2.83L7.185 21.5a1.78 1.78 0 01-1.02-1.612V8.764c0-1.25 1.25-2.139 2.435-1.678l3.455 1.283zM12.055 8.37v15.465M17.945 6.165v14.84" />
        </>
      )
    case "menu":
      return (
        <>
          <title>Menu</title>
          <path d="M8 10.99h15M8 14.99h15M8 18.99h15" />
        </>
      )
    case "close":
      return (
        <>
          <title>Close</title>
          <path d="M10 10l10 10m-10 0l10-10" />
        </>
      )
    case "filter":
      return (
        <>
          <title>Filter</title>
          <path d="M6.851 17.77a2.681 2.681 0 002.679-2.684c0-1.482-1.2-2.683-2.679-2.683a2.681 2.681 0 00-2.679 2.683c0 1.482 1.2 2.684 2.679 2.684zM14.887 24a2.681 2.681 0 002.679-2.683c0-1.483-1.2-2.684-2.679-2.684a2.681 2.681 0 00-2.679 2.684c0 1.482 1.2 2.683 2.679 2.683zM20.244 11.54a2.681 2.681 0 002.679-2.684c0-1.482-1.2-2.683-2.679-2.683a2.681 2.681 0 00-2.678 2.683c0 1.482 1.199 2.684 2.678 2.684zM4.172 8.856h13.393M22.923 8.856h2.679M9.53 15.086H25.6M12.208 21.317H4.172M17.566 21.317h8.036" />
        </>
      )
    case "filterOpen":
      return (
        <>
          <title>Filter Open</title>
          <path d="M22.923 12.403a2.681 2.681 0 00-2.679 2.683c0 1.482 1.2 2.684 2.679 2.684a2.681 2.681 0 002.679-2.684c0-1.482-1.2-2.683-2.68-2.683zM14.887 6.173a2.681 2.681 0 00-2.679 2.683c0 1.482 1.2 2.684 2.679 2.684a2.681 2.681 0 002.679-2.684c0-1.482-1.2-2.683-2.68-2.683zM9.53 18.633a2.681 2.681 0 00-2.679 2.684c0 1.482 1.2 2.683 2.679 2.683a2.681 2.681 0 002.678-2.683c0-1.483-1.199-2.684-2.678-2.684zM25.602 21.317H12.208M6.851 21.317H4.172M20.244 15.086H4.172M17.566 8.856h8.036M12.208 8.856H4.172" />
        </>
      )
    case "film":
      return (
        <>
          <title>Film</title>
          <path d="M13.892 21.882c3.224 0 5.838-2.528 5.838-5.647 0-3.118-2.614-5.647-5.838-5.647s-5.838 2.529-5.838 5.647c0 3.12 2.614 5.647 5.838 5.647z" />
          <path d="M13.892 20.177c2.25 0 4.074-1.765 4.074-3.942 0-2.176-1.824-3.94-4.074-3.94s-4.074 1.764-4.074 3.94c0 2.177 1.824 3.942 4.074 3.942z" />
          <path d="M9.088 7.235l.73-1.823c.304-.647.973-1.118 1.702-1.118h4.987c.73 0 1.398.412 1.702 1.118l.73 1.823h7.115c.912 0 1.642.706 1.642 1.589v14.294c0 .882-.73 1.588-1.642 1.588H2.946c-.912 0-1.642-.706-1.642-1.588V8.824c0-.883.73-1.589 1.642-1.589h6.142v0z" />
          <path d="M23.5 13c.974 0 1.764-.764 1.764-1.706s-.79-1.706-1.764-1.706-1.764.764-1.764 1.706S22.526 13 23.5 13z" />
        </>
      )
    case "quote":
      return (
        <>
          <title>Quote</title>
          <path d="M3.54 15.125l-.04.094V24.5h9.02v-9.68H9.134l3.357-9.656.23-.664H8.048l-.13.305-4.378 10.32zm13.98 0l-.04.094V24.5h9.02v-9.68h-3.384l3.356-9.656.231-.664H22.03l-.13.305-4.379 10.32z" />
        </>
      )
    case "morning":
      return (
        <>
          <title>Morning</title>
          <path
            d="M21.607 26a6.219 6.219 0 00-12.419 0H8.187a7.219 7.219 0 0114.421 0h-1.001z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
          <path d="M2 25.5h26.125M18.755 20.704l3.437-5.954M15.25 19.125v-3.437M21.688 23.161l2.977-1.719M6.375 21.442l2.977 1.719M11.192 21.204L7.754 15.25" />
        </>
      )
    case "afternoon":
      return (
        <>
          <title>Afternoon</title>
          <path
            d="M15.398 21.563a6.219 6.219 0 100-12.438 6.219 6.219 0 000 12.438zm0 1a7.219 7.219 0 100-14.438 7.219 7.219 0 000 14.438z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
          <path d="M18.755 9.704l3.437-5.954M15.25 8.125V4.688M21.688 12.161l2.977-1.719M6.375 10.442l2.977 1.719M11.192 10.204L7.754 4.25M22 15.5h6.875M2 15.5h6.875M18.754 20.89l3.438 5.954M15.25 22.469v3.438M21.688 18.433l2.977 1.719M6.375 20.152l2.977-1.72M11.192 20.39l-3.438 5.954" />
        </>
      )
    case "evening":
      return (
        <>
          <title>Evening</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.666 9.262a6.503 6.503 0 000 12.476 7.5 7.5 0 110-12.475z"
          />
          <path
            d="M20.666 9.262l.282.96 2.05-.602-1.776-1.189-.556.831zm0 12.476l.556.83 1.776-1.188-2.05-.602-.282.96zM17 15.5a5.503 5.503 0 013.948-5.278l-.564-1.92A7.503 7.503 0 0015 15.5h2zm3.948 5.278A5.503 5.503 0 0117 15.5h-2a7.503 7.503 0 005.384 7.197l.564-1.919zm-.838.129A6.465 6.465 0 0116.5 22v2c1.745 0 3.37-.527 4.722-1.431l-1.112-1.662zM16.5 22a6.5 6.5 0 01-6.5-6.5H8a8.5 8.5 0 008.5 8.5v-2zM10 15.5A6.5 6.5 0 0116.5 9V7A8.5 8.5 0 008 15.5h2zM16.5 9c1.337 0 2.578.403 3.61 1.094l1.112-1.663A8.466 8.466 0 0016.5 7v2z"
            mask="url(#prefix__a)"
          />
        </>
      )
    case "file":
      return (
        <>
          <title>File</title>
          <path d="M22.666 25.665H6.333V4.335H17.8l4.866 5.232v16.098z" />
          <path d="M17.8 4.335v5.232h4.866M9.134 14.396H17.2M9.134 17.616h11.133M9.134 20.634H17.2" />
        </>
      )
    case "arrowRight":
      return (
        <>
          <title>Arrow Right</title>
          <path
            d="M27.339 14.65H2M18.893 6l8.446 8.65-8.446 8.649"
            strokeMiterlimit={10}
          />
        </>
      )
    case "construction":
      return (
        <>
          <title>Construction</title>
          <path
            d="M29.508 10.724v-5.06c0-.645-.262-1.128-.704-1.561a2.24 2.24 0 00-1.582-.624H2.716a2.24 2.24 0 00-1.58.624 2.162 2.162 0 00-.644 1.562v5.12c0 .613.237 1.164.643 1.561a2.24 2.24 0 001.581.624h1.286v12.148c0 .163.02.335.088.507.069.174.175.322.309.453.291.285.608.383.965.383H7.15c.356 0 .673-.098.965-.383a1.29 1.29 0 00.308-.453c.068-.172.089-.344.089-.507v-5.402h12.977v5.402c0 .163.02.335.088.507.069.174.174.322.308.453.292.285.61.383.966.383h1.847c.357 0 .674-.098.965-.383.134-.131.24-.28.308-.453.068-.172.089-.344.089-.507V12.91h1.224a2.24 2.24 0 001.581-.624c.406-.397.643-.948.643-1.562zm-8.02 2.246v4.06H8.513v-4.06h12.977zM10.224 6.165l-4.2 4.059h-2.07l4.2-4.06h2.07zm7.02 0l-4.2 4.059h-2.07l4.198-4.06h2.071zm7.019 0l-4.2 4.059h-2.07l4.199-4.06h2.07zm.693 4.059l1.767-1.699v1.699h-1.767z"
            strokeMiterlimit={10}
          />
        </>
      )
    case "chevronRight":
      return (
        <>
          <title>Chevron Right</title>
          <path d="M10.893 6l8.446 8.65-8.446 8.649" strokeMiterlimit={10} />
        </>
      )
    case "chevronLeft":
      return (
        <>
          <title>Chevron Left</title>
          <path d="M19.339 23.299l-8.446-8.65L19.339 6" strokeMiterlimit={10} />
        </>
      )
    case "chevronDown":
      return (
        <>
          <title>Chevron Down</title>
          <path
            d="M23.765 10.426l-8.65 8.447-8.649-8.447"
            strokeMiterlimit={10}
          />
        </>
      )
    case "chevronUp":
      return (
        <>
          <title>Chevron Up</title>
          <path d="M6.466 18.873l8.65-8.447 8.65 8.447" strokeMiterlimit={10} />
        </>
      )
    case "bicycle":
      return (
        <>
          <title>Bicycle</title>
          <path d="M13.346 13.021l-.345.346.338.354 2.244 2.346v6.016H14V16.96l-.147-.147-3.05-3.033-.025-.025-.028-.02c-.271-.204-.45-.573-.45-1.117 0-.405.161-.82.504-1.163l3.033-3.034.025-.025.021-.028c.204-.271.573-.45 1.117-.45.51 0 .94.16 1.4.523l2.038 2.039c.948.947 2.188 1.618 3.708 1.748l-.127 1.396c-1.96-.095-3.614-.8-4.824-2.01l-.866-.867-.354-.353-.354.353-2.275 2.275zm7.112-7.23c0 .916-.75 1.667-1.666 1.667a1.673 1.673 0 01-1.667-1.666c0-.916.751-1.667 1.667-1.667.915 0 1.666.751 1.666 1.667zM2.5 20.418c0-2.758 2.16-4.917 4.917-4.917 2.757 0 4.916 2.16 4.916 4.917 0 2.757-2.16 4.916-4.916 4.916-2.758 0-4.917-2.16-4.917-4.916zm15.167 0c0-2.758 2.16-4.917 4.916-4.917 2.758 0 4.917 2.16 4.917 4.917 0 2.757-2.16 4.916-4.917 4.916-2.757 0-4.916-2.16-4.916-4.916z" />
        </>
      )
    case "transit":
      return (
        <>
          <title>Transit</title>
          <path d="M14.8.5C9.1.5 4.5 1.1 4.5 5.7V18c0 2.5 2 4.5 4.5 4.5L7.5 24c3 0 5.8 1.2 7.9 3.3.5.5 1.5 1.9 1.5 1.9l.2-.3 3.5-6.3c2.5 0 4.5-2 4.5-4.5V5.7C25.2 1.1 20.5.5 14.8.5zM9 19.9c-1.1 0-1.9-.9-1.9-1.9 0-1 .8-2 1.9-2 1.1 0 2 .9 2 1.9 0 1-.9 2-2 2zm4.5-7.8H7.1V5.7h6.5v6.4h-.1zm7.1 7.8c-1.1 0-1.9-.9-1.9-1.9 0-1 .9-1.9 1.9-1.9 1.1 0 1.9.9 1.9 1.9 0 1-.8 1.9-1.9 1.9zm2-7.8h-6.5V5.7h6.5v6.4z" />
        </>
      )
    case "car":
      return (
        <>
          <title>Car</title>
          <path d="M24.4 4.9c-.3-.8-1-1.4-2-1.4H7.3c-.9 0-1.7.6-2 1.4l-2.9 8.2v11c0 .8.6 1.4 1.4 1.4h1.4c.8 0 1.4-.6 1.4-1.4v-1.4h16.5v1.4c0 .8.6 1.4 1.4 1.4h1.4c.8 0 1.4-.6 1.4-1.4v-11l-2.9-8.2zM7.3 18.6c-1.1 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1 0 1.2-.9 2.1-2.1 2.1zm15.1 0c-1.1 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1 0 1.2-.9 2.1-2.1 2.1zM5.2 11.8l2.1-6.2h15.1l2.1 6.2H5.2z" />
        </>
      )
    case "scooter":
      return (
        <>
          <title>Scooter</title>
          <path d="M3.8 21c-1.4.5-2.3 1.8-2.3 3.3 0 1.9 1.5 3.4 3.4 3.4 1.5 0 2.8-.9 3.2-2.2h14.4c.4 1.3 1.5 2.3 2.9 2.3 1.7 0 3.1-1.4 3.1-3.1 0-.9-.4-1.7-1-2.2-.2-.2-.4-.3-.6-.4v-.4s.5-.1.6-.1c.2-.1.3-.2.4-.4.2-.5 0-.9-.5-1.2-.6-.3-1.6-.4-2.3-.4-2.2.1-3.9 1.5-4.6 3.4h-11C9 21.6 8 20.5 6.8 19.9L8 12.7c0-.2-.1-.6-.3-.8l1.2-7c.3-.2.5-.6.5-1 0-.7-.6-1.3-1.3-1.3-.7 0-1.2.5-1.2 1.2 0 .3.1.5.3.8l-1.3 7c-.3.1-.5.4-.6.6L3.8 21z" />
        </>
      )
    case "walk":
      return (
        <>
          <title>Walk</title>
          <path d="M12.74 11.222l.178-.893-.85.33-2.25.875-.318.124v4.092H8v-5.044l6.108-2.584c.19-.01.35-.041.48-.067l.01-.002C14.757 8.02 14.868 8 15 8c.666 0 1.274.378 1.705 1.021l1.24 1.986c1.02 1.777 2.976 3.063 5.305 3.226v1.502a8.656 8.656 0 01-5.997-2.938l-.668-.77-.2 1-.75 3.75-.054.27.2.19 2.469 2.352v8.661h-1.5v-7.214l-.155-.148-2.625-2.5-.64-.609-.193.861-2.162 9.61H9.359l3.381-17.028zm6.135-6.847c0 1.099-.901 2-2 2s-2-.901-2-2 .901-2 2-2 2 .901 2 2z" />
        </>
      )

    case "facebook":
      return (
        <>
          <title>Facebook</title>
          <path
            d="M25.608 2H3.38C2.615 2 2 2.615 2 3.38v22.222c0 .76.615 1.38 1.38 1.38h11.97V17.31h-3.255v-3.772h3.255V10.76c0-3.23 1.97-4.983 4.851-4.983 1.38 0 2.568.102 2.912.15v3.375h-1.995c-1.567 0-1.869.741-1.869 1.838v2.41h3.737l-.488 3.772h-3.249V27h6.37c.76 0 1.381-.615 1.381-1.38V3.38A1.394 1.394 0 0025.608 2z"
            strokeMiterlimit={10}
          />
        </>
      )

    case "instagram":
      return (
        <>
          <title>Instagram</title>
          <path
            d="M14.85 3.964c3.51 0 3.93.011 5.314.075 1.281.057 1.976.27 2.441.453a4.096 4.096 0 011.511.983c.46.46.747.896.983 1.51.178.466.396 1.161.454 2.442.063 1.385.074 1.804.074 5.314s-.011 3.93-.074 5.314c-.058 1.282-.27 1.977-.454 2.442a4.096 4.096 0 01-.983 1.511c-.46.46-.896.747-1.51.982-.466.179-1.161.397-2.442.454-1.385.063-1.804.075-5.314.075s-3.93-.012-5.314-.075c-1.281-.057-1.977-.27-2.442-.454a4.096 4.096 0 01-1.51-.982 4.026 4.026 0 01-.983-1.51c-.178-.466-.397-1.161-.454-2.443-.063-1.384-.075-1.803-.075-5.314 0-3.51.012-3.93.075-5.314.057-1.28.27-1.976.454-2.441a4.096 4.096 0 01.982-1.511c.46-.46.896-.747 1.511-.983.465-.178 1.16-.396 2.442-.453 1.39-.058 1.804-.075 5.314-.075zm0-2.367c-3.573 0-4.022.017-5.423.08-1.402.064-2.356.288-3.195.61a6.508 6.508 0 00-2.326 1.516 6.479 6.479 0 00-1.517 2.332c-.327.84-.546 1.793-.609 3.195-.063 1.401-.08 1.85-.08 5.423s.017 4.021.08 5.423c.063 1.402.287 2.356.609 3.194a6.384 6.384 0 001.517 2.327 6.432 6.432 0 002.326 1.517c.84.327 1.793.545 3.195.609 1.401.063 1.85.08 5.423.08s4.021-.017 5.423-.08c1.402-.064 2.355-.288 3.194-.61a6.384 6.384 0 002.327-1.516 6.43 6.43 0 001.517-2.327c.327-.838.545-1.792.609-3.194.063-1.402.08-1.85.08-5.423s-.017-4.022-.08-5.423c-.064-1.402-.288-2.356-.61-3.195a6.457 6.457 0 00-1.516-2.326 6.431 6.431 0 00-2.327-1.517c-.838-.327-1.792-.546-3.194-.609-1.402-.069-1.85-.086-5.423-.086z"
            strokeMiterlimit={10}
          />
          <path
            d="M14.85 7.997a6.756 6.756 0 00-6.756 6.756 6.752 6.752 0 006.756 6.756 6.752 6.752 0 006.756-6.756 6.756 6.756 0 00-6.756-6.756zm0 11.134a4.386 4.386 0 01-4.384-4.384 4.386 4.386 0 014.384-4.383 4.383 4.383 0 110 8.767zM21.87 9.307a1.58 1.58 0 100-3.16 1.58 1.58 0 000 3.16z"
            strokeMiterlimit={10}
          />
        </>
      )
    case "twitter":
      return (
        <>
          <title>Twitter</title>
          <path
            d="M28 7.366c-.96.4-1.987.673-3.065.796 1.1-.625 1.947-1.613 2.348-2.794a11.074 11.074 0 01-3.387 1.223A5.493 5.493 0 0020.001 5c-2.946 0-5.334 2.259-5.334 5.047 0 .395.045.78.135 1.148-4.436-.208-8.365-2.216-10.996-5.271a4.843 4.843 0 00-.722 2.537c0 1.751.942 3.295 2.376 4.203a5.525 5.525 0 01-2.416-.63v.063c0 2.446 1.84 4.486 4.28 4.951a5.657 5.657 0 01-2.41.085c.676 2.009 2.646 3.466 4.983 3.504C8.068 21.993 5.771 22.8 3.27 22.8c-.429 0-.858-.022-1.27-.07A15.738 15.738 0 0010.18 25c9.816 0 15.178-7.69 15.178-14.36 0-.22-.005-.438-.017-.652A10.766 10.766 0 0028 7.366z"
            strokeMiterlimit={10}
          />
        </>
      )
    case "megaphone":
      return (
        <>
          <title>Megaphone</title>
          <path d="M24.707 3.748l-5.08 2.524a20.534 20.534 0 01-8.09 2.244l-7.244.561v7.2l7.244.56c2.822.187 5.55.935 8.09 2.244l5.08 2.618V3.748zM4.293 10.48l-1.506.467c-.752.187-1.317.935-1.317 1.777 0 .841.565 1.495 1.317 1.776l1.506.467V10.48z" />
          <path d="M24.707 17.866l.094-.094c1.694-1.122 2.635-3.085 2.635-5.048 0-1.964-.941-3.927-2.635-5.05l-.188-.093.094 10.285zM6.645 16.463l.752 6.452c.188 1.496 1.411 2.617 3.01 2.617h1.13c.846 0 1.41-.747 1.223-1.589l-2.352-7.386" />
        </>
      )
    case "vending":
      return (
        <>
          <title>Vending</title>
          <path
            d="M2.456 9.87l5.396-7.352h14.239l5.396 7.352H2.456zM21.683 9.87v6.896M8.35 9.87v6.896M6.31 25.717H3.816v-8.951h22.401v8.95H23.77M19.007 25.717h-7.936"
            strokeMiterlimit={10}
          />
          <path
            d="M21.501 28a2.412 2.412 0 002.404-2.42 2.412 2.412 0 00-2.404-2.42 2.412 2.412 0 00-2.403 2.42A2.412 2.412 0 0021.5 28zM8.487 28a2.412 2.412 0 002.403-2.42 2.412 2.412 0 00-2.403-2.42 2.412 2.412 0 00-2.404 2.42A2.412 2.412 0 008.487 28z"
            strokeMiterlimit={10}
          />
        </>
      )
    case "parking":
      return (
        <>
          <title>Parking</title>
          <path
            d="M15.475 27.754C22.392 27.754 28 22.146 28 15.229c0-6.917-5.608-12.524-12.525-12.524-6.917 0-12.524 5.607-12.524 12.524 0 6.917 5.607 12.525 12.524 12.525z"
            strokeMiterlimit={10}
          />
          <path
            d="M12.918 22.943V8.512s1.387-.044 2.99-.044c2.428 0 4.725.737 4.725 3.684 0 3.164-2.21 4.16-4.724 4.16-1.604 0-2.99.044-2.99.044"
            strokeMiterlimit={10}
          />
        </>
      )

    case "search":
      return (
        <>
          <title>Search</title>
          <g clipPath="url(#prefix__clip0)" strokeMiterlimit={10}>
            <path d="M13.5 22.5a9 9 0 100-18 9 9 0 000 18zM25.69 25.69l-6.24-6.24" />
          </g>
          <defs>
            <clipPath id="prefix__clip0">
              <path d="M4 4h22.04v22.04H4z" />
            </clipPath>
          </defs>
        </>
      )

    case "x":
      return (
        <>
          <title>X</title>
          <path d="M22.476 3.249h3.987l-8.71 9.956L28 26.75h-8.023l-6.284-8.216-7.19 8.216h-3.99L11.83 16.1 2 3.25h8.227l5.68 7.51 6.57-7.511ZM7.75 5.25l14.58 19.42" />
        </>
      )

    case "tiktok":
      return (
        <>
          <title>TikTok</title>
          <path d="M27.531 7.89v4.07c-1.57-.108-3.125-.47-4.536-1.137l-.006-.003c-.677-.308-1.313-.703-1.96-1.126l-.77-.504-.003.92a1064.36 1064.36 0 0 0-.003 4.113c0 2.274.002 4.543-.022 6.81-.097 1.659-.643 3.3-1.596 4.654l-.004.006c-1.548 2.269-4.237 3.747-6.983 3.795h-.02c-1.68.094-3.37-.364-4.818-1.219-2.387-1.406-4.066-3.984-4.317-6.739a22.699 22.699 0 0 1-.013-1.796 8.882 8.882 0 0 1 3.05-5.853l.004-.003c1.864-1.617 4.428-2.431 6.864-2.102.005.913-.01 1.813-.027 2.72-.01.583-.02 1.17-.025 1.764-1.2-.222-2.512-.03-3.556.687a4.84 4.84 0 0 0-1.889 2.425c-.261.644-.236 1.348-.216 1.915l.009.285v.032l.005.03c.334 2.28 2.512 4.217 4.894 4.016 1.578-.022 3.06-.933 3.865-2.252l.006-.011.051-.088c.215-.369.51-.876.528-1.466.093-1.661.09-3.322.087-4.97a231.47 231.47 0 0 1 .001-1.748v-.001c.005-1.893.004-3.781.004-5.668-.001-2.978-.002-5.952.018-8.927.814-.01 1.623-.01 2.433-.012.497 0 .995-.001 1.495-.004.187 1.852.908 3.723 2.3 5.067 1.4 1.385 3.302 2.055 5.15 2.32Z" />
        </>
      )
    default:
      return (
        <>
          <title>Calendar</title>
          <path d="M22.468 25.543h-14.5c-1.534 0-2.789-1.243-2.789-2.763V8.418c0-1.52 1.255-2.763 2.79-2.763h14.5c1.534 0 2.789 1.243 2.789 2.763V22.78c0 1.52-1.255 2.763-2.79 2.763zM7.69 10.627h15.058M20.238 8.141V3.17M10.199 8.141V3.17" />
        </>
      )
  }
}

export default symbols
