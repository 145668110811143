import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"
import css from "@styled-system/css"

import { Box, Heading, Icon } from "components"

function AccordionGroup({ id, items, onChange, preExpanded }) {
  return (
    <Accordion
      allowZeroExpanded={true}
      preExpanded={preExpanded}
      css={css({
        borderLeft: "4px solid",
        borderRight: "4px solid",
        borderBottom: "4px solid",
        borderColor: "text",
      })}
    >
      {items.map((item, index) => {
        if (item) {
          return (
            <AccordionItem
              key={"accordionGroup" + id + index}
              uuid={"accordionGroup" + id + index}
              id={item.id}
              css={css({
                py: "layout.2",
                px: "layout.2",
                borderTop: "4px solid",
                borderColor: "text",
              })}
              onClick={() => {
                if (onChange) {
                  onChange(item.object)
                }
              }}
            >
              <AccordionItemHeading
                css={css({
                  color: "text",
                })}
              >
                <AccordionItemButton
                  css={css({
                    display: "flex",
                    cursor: "pointer",
                    "&:focus": { outline: 0 },
                    '&[aria-expanded="true"]': {
                      ".control": {
                        "> span:last-of-type": {
                          transform: "translate(-50%, -50%) scaleY(0)",
                        },
                      },
                    },
                  })}
                >
                  {item.icon && (
                    <Icon
                      symbol={item.icon}
                      size={[24, null, null, 40]}
                      mr="layout.1"
                    />
                  )}
                  <Heading
                    children={item.heading}
                    as="span"
                    size={700}
                    css={css({
                      flex: 1,
                    })}
                  />

                  <Box
                    as="span"
                    className="control"
                    position="relative"
                    display="block"
                    width={[24, null, null, 24]}
                    height={[24, null, null, 24]}
                    mt={[0, null, null, 12]}
                    ml={3}
                    verticalAlign="middle"
                  >
                    <Box
                      as="span"
                      position="absolute"
                      top="50%"
                      left="50%"
                      width="100%"
                      height={1}
                      bg="text"
                      css={{
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                    <Box
                      as="span"
                      position="absolute"
                      top="50%"
                      left="50%"
                      width="1px"
                      height="100%"
                      bg="text"
                      css={{
                        transform: "translate(-50%, -50%) scaleY(1)",
                        transformOrigin: "50% 50%",
                        transition: "transform 200ms ease-out",
                      }}
                    />
                  </Box>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel css={css({ pt: "layout.3" })}>
                <Box>{item.children}</Box>
              </AccordionItemPanel>
            </AccordionItem>
          )
        } else {
          return null
        }
      })}
    </Accordion>
  )
}

AccordionGroup.defaultProps = {
  color: "text.default",
  id: "accordion",
  items: [],
}

export default AccordionGroup
