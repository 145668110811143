import React, { useState } from "react"
import css from "@styled-system/css"

const PhotoSvg = () => (
  <svg
    width="47"
    height="40"
    viewBox="0 0 47 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M21.2001 30.4C26.502 30.4 30.8001 26.1019 30.8001 20.8C30.8001 15.498 26.502 11.2 21.2001 11.2C15.8982 11.2 11.6001 15.498 11.6001 20.8C11.6001 26.1019 15.8982 30.4 21.2001 30.4Z"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M21.2 27.5001C24.9003 27.5001 27.9 24.5004 27.9 20.8001C27.9 17.0998 24.9003 14.1001 21.2 14.1001C17.4997 14.1001 14.5 17.0998 14.5 20.8001C14.5 24.5004 17.4997 27.5001 21.2 27.5001Z"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M13.3 5.5L14.5 2.4C15 1.3 16.1 0.5 17.3 0.5H25.5C26.7 0.5 27.8 1.2 28.3 2.4L29.5 5.5H41.2C42.7 5.5 43.9 6.7 43.9 8.2V32.5C43.9 34 42.7 35.2 41.2 35.2H3.2C1.7 35.2 0.5 34 0.5 32.5V8.2C0.5 6.7 1.7 5.5 3.2 5.5H13.3V5.5Z"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M37.0001 15.3C38.6017 15.3 39.9001 14.0016 39.9001 12.4C39.9001 10.7984 38.6017 9.5 37.0001 9.5C35.3985 9.5 34.1001 10.7984 34.1001 12.4C34.1001 14.0016 35.3985 15.3 37.0001 15.3Z"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M34.9001 39.4001C40.8648 39.4001 45.7001 34.5647 45.7001 28.6C45.7001 22.6354 40.8648 17.8 34.9001 17.8C28.9354 17.8 24.1001 22.6354 24.1001 28.6C24.1001 34.5647 28.9354 39.4001 34.9001 39.4001Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path d="M28.8999 28.3H40.8999" stroke="black" stroke-miterlimit="10" />
      <path d="M34.8999 22.3V34.3" stroke="black" stroke-miterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="46.2" height="39.9" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

function PhotoInput({ onChange, ...rest }) {
  const [photo, setPhoto] = useState("")

  return (
    <label
      css={css({
        position: "relative",
        minHeight: [64, 96],
        minWidth: [64, 96],
        cursor: "pointer",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        border: "4px solid",
        borderColor: "text",
        "> input[type=file]": {
          display: "none",
        },
      })}
    >
      {!photo && <PhotoSvg />}
      {photo && (
        <span
          css={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundImage: "url(" + photo + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      )}
      <input
        type="file"
        accept="image/*"
        onChange={e => {
          if (e.target.files && e.target.files[0]) {
            var reader = new FileReader()

            reader.onload = function(file) {
              setPhoto(file.target.result)
            }

            reader.readAsDataURL(e.target.files[0])
          }
          if (onChange) {
            onChange(e)
          }
        }}
        {...rest}
      />
    </label>
  )
}

export default PhotoInput
