import React from "react"
import styled from "@emotion/styled"
import css from "@styled-system/css"

import { Box } from "components"
import headings from "components/theme/src/headings"
import paragraph from "components/theme/src/paragraph"

const StyledBox = styled(Box)(props =>
  css({
    width: "100%",
    borderCollapse: "collapse",
    border: "4px solid",
    borderColor: "text",
    "tr:first-of-type": {
      bg: "text",
      td: {
        py: "layout.1",
        px: "layout.1",
        border: "4px solid",
        borderColor: "text",
        ...headings["300"],
        color: "background",
      },
    },
    tbody: {
      td: {
        py: "layout.1",
        px: "layout.1",
        border: "4px solid",
        borderColor: "text",
        ...paragraph[props.size],
        color: "text",
      },
    },
    "td:first-of-type": {
      pl: "layout.3",
    },
  })
)

const Table = ({ ...rest }) => (
  <StyledBox data-component-id="table" as="table" {...rest} />
)

Table.defaultProps = {
  size: "500",
}

export default Table
