import React from "react"
import shortid from "shortid"
import css from "@styled-system/css"
import { hideVisually } from "polished"

import { Box, Icon, Text } from "components"

const Checkbox = ({ bg, children, indeterminate, size, ...rest }) => {
  const uid = shortid.generate()

  return (
    <Text
      data-component-id="checkbox"
      role="checkbox"
      as="label"
      // htmlFor={uid}
      size={size}
      position="relative"
      display="block"
      minHeight={24}
      pl={`calc(${24 + "px"} + 12px)`}
      css={{ userSelect: "none", cursor: "pointer" }}
    >
      <Box
        data-component-id="checkbox.hiddenInput"
        as="input"
        id={uid}
        type="checkbox"
        ref={el => el && (el.indeterminate = indeterminate)}
        css={css({
          ...hideVisually(),
          "+ div": {
            ".icon": {
              opacity: 0,
            },
          },
          ":checked + div": {
            ".icon": {
              opacity: 1,
            },
          },
        })}
        {...rest}
      />
      <Box
        data-component-id="checkbox.control"
        position="absolute"
        top={0}
        left={0}
        display="block"
        width={24}
        height={24}
        border="4px solid"
        borderColor="text"
        borderRadius={0}
      >
        <Icon
          className="icon"
          symbol="close"
          size={24}
          strokeWidth={2}
          position="absolute"
          top="50%"
          left="50%"
          color="text"
          css={{ transform: "translate(-50%, -50%)" }}
        />
      </Box>
      <Box
        data-component-id="checkbox.text"
        pt={["spacing.2", null, null, "spacing.1"]}
      >
        {children}
      </Box>
    </Text>
  )
}

Checkbox.defaultProps = {
  bg: "white",
  size: 400,
}

export default Checkbox
