import React from "react"

import { Box, Icon, Text } from "components"

import buttonStyles from "./buttonStyles"
import { useTheme } from "@emotion/react"
import { ellipsis } from "polished"

const getTextSizeForControlHeight = height => {
  if (height <= 24) return 300
  if (height <= 28) return 300
  if (height <= 32) return 300
  if (height <= 36) return 400
  if (height <= 40) return 400
  return 500
}

const getIconSizeForButton = height => {
  if (height <= 28) return 12
  if (height <= 32) return 12
  if (height <= 40) return 16
  if (height <= 48) return 18
  return 20
}

function Button({
  appearance,
  children,
  height,
  iconAfter,
  iconBefore,
  intent,
  ...rest
}) {
  let is = getIconSizeForButton(height)
  let p = Math.round(height / 2)
  let ts = getTextSizeForControlHeight(height)

  return (
    <Text
      data-component-id="buttons.button"
      size={ts}
      position="relative"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="nowrap"
      height={height}
      m={0}
      px={p}
      py={0}
      border={0}
      borderRadius={0}
      fontFamily="extended"
      fontWeight={700}
      textAlign="center"
      lineHeight={height + "px"}
      overflow="hidden"
      css={{
        ...buttonStyles(appearance, intent, useTheme()),
        textTransform: "uppercase",
        userSelect: "none",
        appearance: "none",
        cursor: "pointer",
        ":focus": {
          outline: "1px dotted currentColor",
          outlineOffset: "-4px",
        },
        "&:hover": {
          '[data-component-id="buttons.button.iconAfter"]': {
            transform: iconAfter === "arrowRight" && "translateX(4px)",
          },
        },
      }}
      {...rest}
    >
      {iconBefore && (
        <Icon
          data-component-id="buttons.button.iconBefore"
          symbol={iconBefore}
          size={is}
          strokeWidth={2}
          mr={Math.round(is) + "px"}
          ml={"-" + Math.round(is * 0.3) + "px"}
          color="inherit"
        />
      )}
      <Box as="span" css={{ ...ellipsis() }}>
        {children}
      </Box>
      {iconAfter && (
        <Icon
          data-component-id="buttons.button.iconAfter"
          symbol={iconAfter}
          size={is}
          strokeWidth={2}
          mr={"-" + Math.round(is * 0.3) + "px"}
          ml={Math.round(is) + "px"}
          color="inherit"
          css={{ transition: "transform 150ms ease-out" }}
        />
      )}
    </Text>
  )
}

Button.defaultProps = {
  appearance: "default",
  as: "button",
  height: 56,
  intent: "default",
}

export default Button
