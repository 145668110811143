import React from "react"
import css from "@styled-system/css"

import { Heading } from "components"

const FileInput = ({ label, ...rest }) => (
  <label>
    <Heading
      size="100"
      children={label}
      display={"inline-block"}
      mb="layout.1"
    />
    {rest.required && (
      <Heading
        size="100"
        display={"inline-block"}
        children={`Required`}
        opacity={0.3}
        mb="layout.1"
        ml="layout.1"
      />
    )}
    <input
      type="file"
      {...rest}
      css={css({
        display: " block",
      })}
    />
  </label>
)

export default FileInput
