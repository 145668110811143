function buttonStyles(appearance, intent, theme) {
  const intents = {
    default: {
      base: theme.colors.text,
      contrast: theme.colors.background,
    },
    primary: {
      base: theme.colors.brand,
      contrast: theme.colors.background,
    },
  }

  switch (appearance) {
    case "primary":
      return {
        color: "#fff",
        backgroundColor: intents[intent].base,
      }
    case "minimal":
      return {
        color: intents[intent].base,
        backgroundColor: "transparent",
      }
    default:
      return {
        boxShadow: `inset 0 0 0 4px ${intents[intent].base}`,
        color: intents[intent].base,
        backgroundColor: "transparent",
        transition: "all 150ms ease-out",
        ":hover": {
          color: intents[intent].contrast,
          backgroundColor: intents[intent].base,
        },
        "&.selected": {
          color: intents[intent].contrast,
          backgroundColor: intents[intent].base,
        },
      }
  }
}

export default buttonStyles
