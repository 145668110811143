import fonts from "./fonts"

export default {
  "600": {
    fontFamily: fonts.standard,
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "-0.05em",
    "@media screen and (min-width: 52em)": {
      fontSize: "24px",
      lineHeight: "28px",
    },
  },
  "500": {
    fontFamily: fonts.standard,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "-0.05em",
    "@media screen and (min-width: 52em)": {
      fontSize: "18px",
      lineHeight: "22px",
    },
  },
  // Default
  "400": {
    fontFamily: fonts.standard,
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "-0.05em",
  },
  "300": {
    fontFamily: fonts.standard,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: 0,
    "@media screen and (min-width: 52em)": {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
}
