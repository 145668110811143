import React from "react"
import css from "@styled-system/css"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"

function Carousel({ id, dots = true, children, autoPlay }) {
  const sliderSettings = {
    arrows: false,
    autoplay: autoPlay ? autoPlay : null,
    autoplaySpeed: 6000,
    dots: dots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      {/* -- bbbbreak -- */}
      <Slider
        css={css({
          ".slick-dots": {
            m: 0,
            p: 0,
            listStyle: "none",
            textAlign: "center",
            li: {
              display: "inline-block",
              button: {
                display: "block",
                p: "spacing.2",
                border: 0,
                fontSize: "0px",
                lineHeight: "0px",
                bg: "transparent",
                cursor: "pointer",
                "::before": {
                  content: "''",
                  display: "block",
                  width: 10,
                  height: 10,
                  border: "4px solid",
                  borderColor: "text",
                  borderRadius: "50%",
                  bg: "transparent",
                  transition: "background 150ms ease-out",
                },
              },
              "&.slick-active": {
                button: { "::before": { bg: "text" } },
              },
            },
          },
        })}
        {...sliderSettings}
      >
        {children}
      </Slider>
    </>
  )
}

export default Carousel
