import React from "react"

import { Box } from "components"

import symbols from "./symbols"

const Icon = ({ size, symbol, ...rest }) => (
  <Box
    data-component-id="icon"
    as="svg"
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    stroke="currentColor"
    verticalAlign="top"
    {...rest}
  >
    {symbols(symbol)}
  </Box>
)

Icon.defaultProps = {
  color: "text",
  size: 24,
  strokeWidth: 1,
  symbol: "calendar",
}

export default Icon
