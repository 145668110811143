import React from "react"
import { Link as GatsbyLink } from "gatsby"

import {
  AspectRatio,
  Box,
  ColorMode,
  Flex,
  Heading,
  Icon,
  Image,
  Paragraph,
} from "components"

function GuideCard({ title, image, author, excerpt, url, vertical }) {
  return (
    <Flex
      data-component-id="guideCard"
      as={GatsbyLink}
      to={`/guides/${url}/`}
      flexDirection={vertical ? "column" : "row"}
      minHeight="100%"
      border={["4px solid", "8px solid"]}
      borderColor="text"
    >
      {/* ~~~~~~ HORIZONTAL ~~~~~~ */}
      {/* ============ IMAGE ============ */}
      {!vertical && (
        <Flex position="relative" width={["40%", "50%"]} pb="50%">
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            bg="wash"
          >
            <Image
              image={image}
              size="xs"
              style={{ height: "100%" }}
              imgStyle={{ height: "100%", objectFit: "cover" }}
            />
            {/* Icon */}
            <Flex
              position="absolute"
              top={0}
              left={0}
              justifyContent="center"
              alignItems="center"
              width={40}
              height={40}
              mt="-2px"
              ml="-2px"
              border="2px solid"
              borderColor="text"
              bg="background"
            >
              <Icon symbol="book" size={24} color="text" />
            </Flex>
          </Box>
        </Flex>
      )}

      {/* ~~~~~~ VERTICAL ~~~~~~ */}
      {/* ============ IMAGE ============ */}
      {vertical && (
        <AspectRatio ratio="16:9" bg="wash">
          <Image
            image={image}
            size="xs"
            style={{ height: "100%" }}
            imgStyle={{ height: "100%", objectFit: "cover" }}
          />
          {/* Icon */}
          <Flex
            position="absolute"
            top={0}
            left={0}
            justifyContent="center"
            alignItems="center"
            width={40}
            height={40}
            mt="-2px"
            ml="-2px"
            border="2px solid"
            borderColor="text"
            bg="background"
          >
            <Icon symbol="book" size={24} color="text" />
          </Flex>
        </AspectRatio>
      )}

      {/* Content */}
      <ColorMode mode="light">
        <Flex
          flexDirection="column"
          flex={1}
          width={vertical ? "100%" : ["60%", "50%"]}
          borderLeft={!vertical && "1px solid"}
          borderColor="text"
          bg="background"
        >
          {/* Title */}
          <Box
            mb="auto"
            pt={vertical ? "spacing.6" : "layout.2"}
            px={["spacing.4", "spacing.6"]}
            borderTop={vertical && "4px solid"}
            borderColor="text"
          >
            <Heading size={600}>
              <GatsbyLink to={`/guides/${url}/`}>{title}</GatsbyLink>
            </Heading>
          </Box>

          {/* Divider */}
          <Box
            as="hr"
            width="50%"
            height="1px"
            mx={0}
            my={["spacing.3", "spacing.5"]}
            border={0}
            bg="text"
          />

          {/* Info */}
          <Box px={["spacing.4", "spacing.6"]} pb={["spacing.4", "spacing.6"]}>
            <Heading size={100} mb={["spacing.1", "spacing.2"]}>
              {"by " + author}
            </Heading>
            <Paragraph size={300}>{excerpt}</Paragraph>
          </Box>
        </Flex>
      </ColorMode>
    </Flex>
  )
}

export default GuideCard
