import React, { useState } from "react"
import LastImage from "react-last-image"

const sizes = {
  xl: 2560,
  lg: 1900,
  md: 1200,
  sm: 800,
  xs: 400,
  xxs: 200,
  xxxs: 64,
}

function Image({ image, appendQuery, size, format, ...props }) {
  const [images] = useState(() => {
    if (image && image.url) {
      let images = []
      let ratio = image.width / image.height
      let selectedSize = ""
      if (size) {
        selectedSize = sizes[size]
      }
      images.push({
        url:
          image.url +
          (appendQuery
            ? `?fit=crop&max-w=${selectedSize}&h=${selectedSize /
                ratio}&fm=${format}`
            : ``),
        width: selectedSize ? selectedSize * ratio : image.width,
        height: selectedSize ? selectedSize : image.height,
      })
      images.push({
        url:
          image.url +
          (appendQuery
            ? `?fit=crop&max-w=${16}&h=${16 / ratio}&fm=${format}`
            : ``),
        width: 16 * ratio,
        height: 16,
      })
      return images
    }
  })
  if (image && image.url) {
    return <LastImage images={images} alt={image.alt} {...props} />
  } else {
    return null
  }
}

Image.defaultProps = {
  format: "jpg",
  appendQuery: true,
}

export default Image
